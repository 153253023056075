import { post, get, patch } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ICountry } from "../types/countries";

export const useFetchCountry = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        const encodedSearchFilter = encodeURIComponent(data.searchFilter);
        const response = await get("country/list?searchFilter=" + encodedSearchFilter + "&isServiceable=" + data.isServiceable);
        return response.data;
    }, options);
};

export const useAddCountry = (options?: UseMutationOptions<any, any, ICountry, any>) => {
    return useMutation(async (newCountry: ICountry) => {
        const response = await post("country", newCountry);
        return response.data;
    }, options);
};

export const useDeleteCountry = (options: any) => {
    return useMutation(async (countryId) => {
        const response = await patch(`country/${countryId}/delete`, {});
        return response.data;
    }, options);
};