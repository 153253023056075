import { isValidJSON } from "../helpers/common";

const storagePrefix = 'trip_';

const storage = {
    getToken: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}token`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}token`)) : "";
    },
    setToken: (token) => {
        window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
    },
    clearToken: () => {
        window.localStorage.removeItem(`${storagePrefix}token`);
    },
    getRefreshToken: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}refreshToken`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}refreshToken`)) : "";
    },
    setRefreshToken: (token) => {
        window.localStorage.setItem(`${storagePrefix}refreshToken`, JSON.stringify(token));
    },
    clearRefreshToken: () => {
        window.localStorage.removeItem(`${storagePrefix}refreshToken`);
    },
    setEmailId: (emailId) => {
        window.localStorage.setItem(`${storagePrefix}emailId`, emailId);
    },
    getEmailId: () => {
        return (window.localStorage.getItem(`${storagePrefix}emailId`));
    },
    clearEmailId: () => {
        window.localStorage.removeItem(`${storagePrefix}emailId`);
    },
    setPhoneNumber: (phoneNumber) => {
        window.localStorage.setItem(`${storagePrefix}phoneNumber`, phoneNumber);
    },
    getPhoneNumber: () => {
        return (window.localStorage.getItem(`${storagePrefix}phoneNumber`));
    },
    clearPhoneNumber: () => {
        window.localStorage.removeItem(`${storagePrefix}phoneNumber`);
    },
    setCodeType: (codeType) => {
        window.localStorage.setItem(`${storagePrefix}codeType`, codeType);
    },
    getCodeType: () => {
        return (window.localStorage.getItem(`${storagePrefix}codeType`));
    },
    clearCodeType: () => {
        window.localStorage.removeItem(`${storagePrefix}codeType`);
    },
    setDialingCode: (dialingCode) => {
        window.localStorage.setItem(`${storagePrefix}dialingCode`, dialingCode);
    },
    getDialingCode: () => {
        return (window.localStorage.getItem(`${storagePrefix}dialingCode`));
    },
    clearDialingCode: () => {
        window.localStorage.removeItem(`${storagePrefix}dialingCode`);
    },
    setCountryId: (countryId) => {
        window.localStorage.setItem(`${storagePrefix}countryId`, countryId);
    },
    getCountryId: () => {
        return (window.localStorage.getItem(`${storagePrefix}countryId`));
    },
    clearCountryId: () => {
        window.localStorage.removeItem(`${storagePrefix}countryId`);
    },
    setUserRoleId: (userRoleId) => {
        window.localStorage.setItem(`${storagePrefix}userRoleId`, userRoleId);
    },
    getUserRoleId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userRoleId`));
    },
    clearUserRoleId: () => {
        window.localStorage.removeItem(`${storagePrefix}userRoleId`);
    },
    setPreviousUrlPath: (path) => {
        window.localStorage.setItem(`${storagePrefix}previousUrl`, path);
    },
    getPreviousUrlPath: () => {
        return (window.localStorage.getItem(`${storagePrefix}previousUrl`));
    },
    clearPreviousUrlPath: () => {
        window.localStorage.removeItem(`${storagePrefix}previousUrl`);
    },
    setUserStatusId: (userRoleId) => {
        window.localStorage.setItem(`${storagePrefix}userStatusId`, userRoleId);
    },
    getUserStatusId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userStatusId`));
    },
    setUserId: (userRoleId) => {
        window.localStorage.setItem(`${storagePrefix}userId`, userRoleId);
    },
    getUserId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userId`));
    },
    setLoginType: (loginType) => {
        return (window.localStorage.setItem(`${storagePrefix}loginType`, loginType));
    },
    getLoginType: () => {
        return (window.localStorage.getItem(`${storagePrefix}loginType`));
    }
};

export {
    storage
};