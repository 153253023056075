import { get } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useFetchChatList = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("chat/list?searchFilter=" + data.searchFilter);
    }, options);
};

export const useGetChatDetail = (options?: UseMutationOptions<any, any, { travellerId: string, agentId: string }, any>) => {
    return useMutation(
        async ({ travellerId, agentId }: { travellerId: string, agentId?: string }) => {
            return await get("chat/detail?travellerId=" + travellerId + "&agentId=" + agentId);
        },
        options
    );
};