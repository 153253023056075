import { post, get, patch } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useGetMostVisitedPlaces = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("mostvisitedplace/list?searchFilter=" + data.searchFilter);
    }, options);
};

export const useAddMostVisitedPlaces = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("mostvisitedplace", data);
    }, options);
};

export const useDeleteMostVisitedPlace = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (placeId) => {
        return await patch(`mostvisitedplace/${placeId}/delete`, {});
    }, options);
};