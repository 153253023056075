import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Input, message, Modal, Checkbox, Spin, Col, Drawer, Form, Row, Select, } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import Column from "antd/es/table/Column";
import { useFetchCountry } from "../services/countries.service";
import { useFetchStates } from "../services/states.service";
import { compareObjects, isNotNullUndefined, isNotNullUndefinedBlank } from "../helpers/common";
import { useAddCity, useDeleteCity, useFetchCities } from "../services/cities.service";
import { ICity } from "../types/cities";
import { storage } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import { eventEmitter } from "../helpers/eventemitter";
import { ICountry } from "../types/countries";
import { IState } from "../types/states";

const Cities: React.FC = () => {
    const { Search } = Input;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [countries, setCountry] = useState<ICountry[]>([]);
    const [states, setStates] = useState<IState[]>([]);
    const [cities, setCities] = useState<any[]>([]);
    const [countryOptions, setCountryOptions] = useState<any>([]);
    const isAddCity = useRef(false);
    const [editingCity, setEditingCity] = useState<ICity | null>(null);
    const [modalType, setModalType] = useState<'delete' | 'update' | 'add' | ''>('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [cityId, setCityId] = useState();
    const [formUpdateValues, setFormUpdateValues] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const formInitialValues = useRef<any>({});
    const formUpdatedValues = useRef<any>({});
    const searchValue = useRef<string>("");

    useEffect(() => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        if (userRoleId !== 1) {
            navigate("/");
        }
        else {
            getCitiesList("", "", "", "", "");
            getCountry.mutate({
                searchFilter: "",
                isServiceable: ""
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showDrawer = (isEditState: boolean) => {
        isAddCity.current = !isEditState;
        if (isAddCity.current) {
            setEditingCity(null);
            form.resetFields();
        }
        setOpen(true);
        eventEmitter.emit("clickableChange", true);
    };

    const onClose = () => {
        form.resetFields();
        setOpen(false);
        eventEmitter.emit("clickableChange", false);
    };

    const onDeleteCity = (record: any) => {
        setModalType('delete');
        setIsConfirmModalVisible(true);
        setFormUpdateValues({ cityName: record.cityName });
        setCityId(record.cityId);
    };

    const deleteCity = useDeleteCity({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                getCitiesList("", "", "", "", "");
                message.success("City has been deleted successfully!");
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            if (isNotNullUndefined(error.response) && isNotNullUndefined(error.response.data) && isNotNullUndefinedBlank(error.response.data.message)) {
                message.error(error.response.data.message);
            } else {
                message.error("An unexpected error occurred.");
            }
        },
    });

    const getCitiesList = async (stateId: string, countryId: string, search: string, isFavourite: any, isServiceable: any) => {
        setIsLoading(true);
        getCitiesMutation.mutate({
            stateId: stateId,
            countryId: countryId,
            searchFilter: search,
            isFavourite: isFavourite,
            isServiceable: isServiceable
        });
    };

    const getCitiesMutation = useFetchCities({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response)) {
                setCities(response.data);
                setIsLoading(false);
            }
        }, onError: (error) => {
            message.error(error.message);
            setIsLoading(false);
        }
    });

    const getStatesList = async (countryId: number) => {
        getStatesMutation.mutate({
            countryId: countryId,
            searchFilter: ""
        });
    };

    const getStatesMutation = useFetchStates({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response)) {
                setStates(response.data);
                if (!isAddCity.current) {
                    form.setFieldValue("state", formInitialValues.current.state);
                }
            } else {
                message.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const getCountry = useFetchCountry({
        onSuccess: (response: any) => {
            if (response != null && response !== undefined) {
                setCountry(response);
                setCountryOptions(response.map((data: any) => {
                    return {
                        value: data.countryName.toLowerCase(),
                        label: data.countryName.replace(/\w\S*/g, (txt: any) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
                    };
                }));
            } else {
                message.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const onEditCity = (record: any) => {
        fetchStates(record.countryId);
        setEditingCity(record);
        form.setFieldsValue({
            countryId: record.countryId,
            countryName: record.countryName,
            cityId: record.cityId,
            cityName: record.cityName,
            isFavourite: false, //record.isFavourite,
            isServiceable: record.isServiceable
        });
        formInitialValues.current = { cityName: record.cityName, country: record.countryId, state: record.stateId, isFavourite: false, isServiceable: record.isServiceable }
        showDrawer(true);
    };

    const addCity = useAddCity({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                getCountry.mutate({
                    searchFilter: searchValue.current ?? "",
                    isServiceable: ""
                });
                getCitiesList("", "", "", "", "");
                let successMessage = isAddCity.current ? "City has been added successfully!" : "City has been updated successfully!";
                setIsLoading(false);
                message.success(successMessage);
            } else {
                setIsLoading(false);
                message.error(response.statusMessage);
            }
            onClose();
            form.resetFields();
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        },
    });

    const handleSave = async () => {
        try {
            if (modalType === 'delete') {
                deleteCity.mutate(cityId);
            }
            else if (modalType === 'update' && editingCity) {
                setIsLoading(true);
                const editedData = { ...editingCity, ...formUpdateValues };
                addCity.mutate({ cityId: editedData.cityId, countryID: editedData.countryId, stateID: editedData.stateId, cityName: editedData.cityName, isFavourite: editedData.isFavourite, isServiceable: editedData.isServiceable });
            }
            else if (modalType === 'add') {
                setIsLoading(true);
                let countryId = countries.find((x: any) => x.countryName.toLowerCase() === formUpdateValues.countryName.toLowerCase())?.countryId;
                addCity.mutate({ cityId: null, countryID: countryId, stateID: formUpdateValues.stateId, cityName: formUpdateValues.cityName, isFavourite: formUpdateValues.isFavourite, isServiceable: formUpdateValues.isServiceable });
            }
            setIsConfirmModalVisible(false);
        } catch (error) {
            message.error('Failed to save');
        }
    };

    const onFinish = (values: any) => {
        setModalType(((isAddCity.current) ? 'add' : 'update'));
        if (modalType === 'update') {
            const changedCountryId = countries.find((x: { countryName: string; }) => x.countryName.toLowerCase() === values.countryName.toLowerCase())?.countryId ?? "";
            formUpdatedValues.current = { cityName: values.cityName, countryId: changedCountryId, stateId: values.state, isFavourite: false, isServiceable: values.isServiceable }
            if (compareObjects(formInitialValues.current, formUpdatedValues.current)) {
                onClose();
                return;
            }
        }
        setIsConfirmModalVisible(true);
        setFormUpdateValues(values);
    }

    const fetchStates = (countryId: number) => {
        getStatesList(countryId);
    };

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'delete') {
            message = "Confirm Deletion!";
        } else if (modalType === 'update') {
            message = "Confirm Update!";
        }
        else if (modalType === 'add') {
            message = "Confirm Add!";
        }
        return message;
    }

    const handleCancel = () => {
        setIsLoading(false);
        setIsConfirmModalVisible(false);
    };

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === 'delete') {
            modalContent = (
                <p>Are you sure you want to delete <b>{formUpdateValues.cityName}</b> City's Delete request? Once deleted, this city will not be recovered. Do you wish to proceed?</p>
            );
        } else if (modalType === 'update') {
            modalContent = (
                <p>Are you sure you want to update <b>{formUpdateValues.cityName}</b> City's Update request? Once updated, this city can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        else if (modalType === 'add') {
            modalContent = (
                <p>Are you sure you want to add <b>{formUpdateValues.cityName}</b> City's Add request? Once added, this city can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        return modalContent;
    }

    const onSearch = (value: any) => {
        getCitiesList("", "", value, "", "");
        searchValue.current = value;
    };

    return (
        <div>
            <div className="flex justify-between align-middle pb-5">
                <h2 className="text-2xl font-semibold text-black">Cities</h2>
                <div className="flex justify-between gap-5">
                    <div className="w-80 max-w-80">
                        <Search
                            placeholder="Search city"
                            size="large"
                            enterButton
                            className="search-field"
                            onSearch={onSearch}
                        />
                    </div>
                    <Button className="h-10 bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out" onClick={() => showDrawer(false)} icon={<PlusOutlined />}>
                        Add City
                    </Button>
                </div>
            </div>
            <Drawer
                title={((isAddCity.current) ? 'Add New' : 'Edit') + ' City'}
                width={370}
                onClose={onClose}
                mask={false}
                open={open}>
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="countryId"
                                label="Country ID"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="countryName" label="Country"
                                rules={[{ required: true, message: 'Please select a Country' }]}>
                                <Select
                                    showSearch
                                    className="font-medium text-neutral-600 h-12"
                                    placeholder="Please select a Country"
                                    optionFilterProp="label"
                                    options={countryOptions}
                                    onChange={fetchStates}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="state" label="State"
                                rules={[{ required: true, message: 'Please select a State' }]}>
                                <Select
                                    showSearch
                                    className="font-medium text-neutral-600 h-12"
                                    placeholder="Please select a State"
                                    optionFilterProp="label"
                                    options={states ? states.map(x => ({ label: x.stateName.replace(/\w\S*/g, (txt: any) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()), value: x.stateId })) : []}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="cityId" label="City ID" style={{ display: 'none' }}>
                                <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="cityName" label="City"
                                rules={[{ required: true, message: 'Please enter your city name' }]}>
                                <Input size="large" placeholder="Please enter your city" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="isFavourite" valuePropName="checked">
                                <Checkbox className="check-box">Add to Favourite Cities</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="isServiceable" valuePropName="checked">
                                <Checkbox className="check-box">Serviceable</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Space className="w-full justify-end">
                        <Button onClick={onClose} className="hover:!text-black hover:!border-black">Cancel</Button>
                        <Button htmlType="submit" className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">{(isAddCity.current) ? 'Add' : 'Update'}</Button>
                    </Space>
                </Form>
            </Drawer>
            <Table dataSource={cities} className="userlist-table" bordered
                locale={{
                    emptyText: isLoading ? <Spin /> : (
                        <div className="ant-empty">
                            <div className="ant-empty-image">
                                <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                        <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                        <g fillRule="nonzero" stroke="#d9d9d9">
                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                            <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="ant-empty-description">No data</div>
                        </div>
                    )
                }}>
                <Column title="City Name" dataIndex="cityName" key="cityName" align="center" />
                <Column title="State / Province Name" dataIndex="stateName" key="stateName" align="center" />
                <Column title="Country Name" dataIndex="countryName" key="countryName" align="center" />
                {/* <Column
                    title="Favourite Cities"
                    dataIndex="isFavourite"
                    key="isFavourite"
                    render={(isFavourite) => isFavourite ? <StarFilled className="text-[26px] text-black" /> : null}
                    align="center"
                /> */}
                <Column
                    title="Serviceable Cities"
                    dataIndex="isServiceable"
                    key="isServiceable"
                    render={(isServiceable) => (
                        <Checkbox className="check-box non-interactive-checkbox" checked={isServiceable ?? false} />
                    )}
                    align="center"
                />
                <Column
                    align="center"
                    title="Action"
                    key="action"
                    render={(_: any, record: any) => (
                        <Space size="middle">
                            <Button onClick={() => onEditCity(record)} icon={<EditOutlined />} className="hover:!border-black hover:!text-black"></Button>
                            <Button onClick={() => onDeleteCity(record)} icon={<DeleteOutlined />} className="hover:!border-black hover:!text-black"></Button>
                        </Space>
                    )}
                />
            </Table>

            <Modal
                title={<span className="custom-modal-title">{getModalTitle()}</span>}
                open={isConfirmModalVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                footer={[
                    <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                        Cancel
                    </Button>,
                    <Button key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                        Confirm
                    </Button>,
                ]}
            >
                {getModelContent()}
            </Modal>
        </div>
    );
}
export default Cities;