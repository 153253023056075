import { useEffect, useRef, useState } from 'react';
import { Row, Col, Radio, RadioChangeEvent, Button, Form, Input, message } from 'antd';
import Images from '../assets/image/index';
import { GooglePlusOutlined } from "@ant-design/icons";
import { useRegister, useSendVerificationCode } from '../services/user.service';
import IResponse from '../types/response';
import { useNavigate } from 'react-router-dom';
import { storage } from '../utils/storage';
import 'react-phone-input-2/lib/style.css'
import { isNotNullUndefinedBlank, onAutoGeneratePassword } from '../helpers/common';
import { googleAuthenticateUrl } from '../helpers/socialauthenticateurl';
//import { useFetchCountry } from '../services/countries.service';
//import { ICountry } from '../types/countries';
//import Password from 'antd/es/input/Password';
import { checkPasswordStrength } from '../helpers/passwordstrength';

const Registration = () => {
    const [form] = Form.useForm();
    // const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [userRoleId, setUserRoleId] = useState(3);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    //const [selectedCountry, setSelectedCountry] = useState<any>();
    //const [country, setCountry] = useState<ICountry[]>([]);
    //const [allCountry, setAllCountry] = useState<ICountry[]>([]);
    const isEmailPhoneNumberRequired = useRef(false);
    const [isPasswordRequired, setIsPasswordRequired] = useState(false);
    // const [phoneNumber, setPhoneNumber] = useState('');
    const [passwordScore, setPasswordScore] = useState<number>(0);
    const [password, setPassword] = useState('');

    useEffect(() => {
        //fetchCountry();
        const email = storage.getEmailId() ?? "";
        setEmail(email);
        form.setFieldsValue({ email: email });
        if (isNotNullUndefinedBlank(email)) {
            onChangeInputValue(null, email);
            storage.clearToken();
            storage.clearRefreshToken();
            storage.clearUserRoleId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const fetchCountry = async () => {
    //     fetchCountryMutation.mutate({
    //         searchFilter: "",
    //         isServiceable: ""
    //     });
    // };

    // const fetchCountryMutation = useFetchCountry({
    //     onSuccess: (response: IResponse) => {
    //         if (response != null && response !== undefined) {
    //             response.data = response;
    //             setCountry(response.data);
    //             setAllCountry(response.data);
    //             const defaultCountry = response.data.find((x: { countryId: number; }) => x.countryId === 1);
    //             if (defaultCountry) {
    //                 const defaultOption = {
    //                     value: defaultCountry.countryId,
    //                     label: (
    //                         <div style={{ display: 'flex', alignItems: 'center' }}>
    //                             <img src={defaultCountry.imageUrl} className='w-5' alt="Country Flag" style={{ marginRight: 8 }} />
    //                             {defaultCountry.dialingCode} {defaultCountry.countryName}
    //                         </div>
    //                     )
    //                 };
    //                 const option = { value: defaultOption.value, label: defaultOption.label.props.children };
    //                 handleCountryCodeChange(option);
    //             }
    //         }
    //     },
    //     onError: (error: any) => {
    //         alert(error.message);
    //     }
    // });

    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setUserRoleId(parseInt(e.target.value));
        // if (parseInt(e.target.value) === 2 && allCountry.length > 0) {
        //     setCountry(allCountry.filter(x => x.countryId === 1));
        //     const defaultCountry = allCountry.find((x: { countryId: number; }) => x.countryId === 1);
        //     if (defaultCountry) {
        //         const defaultOption = {
        //             value: defaultCountry.countryId,
        //             label: (
        //                 <div style={{ display: 'flex', alignItems: 'center' }}>
        //                     <img src={defaultCountry.imageUrl} className='w-5' alt="Country Flag" style={{ marginRight: 8 }} />
        //                     {defaultCountry.dialingCode} {defaultCountry.countryName}
        //                 </div>
        //             )
        //         };
        //         const option = { value: defaultOption.value, label: defaultOption.label.props.children };
        //         handleCountryCodeChange(option);
        //     }
        // }
        // else if (parseInt(e.target.value) === 3 && allCountry.length > 0) {
        //     setCountry(allCountry);
        // }
    };

    const inputChange = (event: any) => {
        const { value } = event.target;
        setPasswordScore(checkPasswordStrength(value));
        form.setFieldValue("password", value);
        setPassword(value);
    };

    const onChangeInputValue = (event: any, values: string = "") => {
        const value = isNotNullUndefinedBlank(values) ? values : event.target.value;
        if (value.length >= 3) {
            if (/^[0-9]+$/.test(value)) {
                //setPhoneNumber(value);
                setEmail('');
                setIsPasswordRequired(false);
                // if (userRoleId === 2) {
                //     setCountry(allCountry.filter(x => x.countryId === 1));
                // }
            } else {
                setEmail(value);
                // setPhoneNumber('');
                setIsPasswordRequired(true);
            }
        } else if (value.length === 0) {
            setEmail('');
            // setPhoneNumber('');
            setIsPasswordRequired(false);
        }
    };

    // const handleCountryCodeChange = (option: any) => {
    //     if (Array.isArray(option.label)) {
    //         const slicedLabel = option.label.slice(0, 2);
    //         const modifiedOption = { ...option, label: slicedLabel };
    //         setSelectedCountry(modifiedOption);
    //     } else {
    //         setSelectedCountry(option);
    //     }
    // };

    const sendVerificationCode = async () => {
        // if (isNotNullUndefinedBlank(phoneNumber)) {
        //phoneNoDialingCode = country.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "";
        // }
        sendVerificationCodeMutation.mutate({
            userEmail: email,
            phoneNumber: "",
            phoneNoDialingCode: phoneNoDialingCode
        });
    };

    const sendVerificationCodeMutation = useSendVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                if (isNotNullUndefinedBlank(email)) {
                    storage.setCodeType("email");
                    storage.setEmailId(email);
                }
                // else if (isNotNullUndefinedBlank(Password)) {
                //     storage.setCodeType("sms");
                //     storage.setPhoneNumber(phoneNumber);
                //storage.setDialingCode(country.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "");
                // }
                message.success("OTP has been sent successfully.");
                navigate('/authorization');
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    let phoneNoDialingCode = "";
    const onRegister = async (values: { password: string }) => {
        // if (!isNotNullUndefinedBlank(email) && !isNotNullUndefinedBlank(phoneNumber)) {
        if (!isNotNullUndefinedBlank(email)) {
            isEmailPhoneNumberRequired.current = true;
        }
        else {
            isEmailPhoneNumberRequired.current = false;
            // if (isNotNullUndefinedBlank(phoneNumber)) {
            //phoneNoDialingCode = country.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "";
            // }
            register.mutate({
                userRoleId: userRoleId,
                email: email,
                //phoneNumber: phoneNumber,
                password: values.password,
                phoneNoDialingCode: phoneNoDialingCode,
            });
            setLoading(true);
        }
        storage.setPreviousUrlPath(window.location.pathname);
    };

    const register = useRegister({
        onSuccess: (response: IResponse) => {
            setLoading(false);
            if (response.statusCode === 200) {
                message.success('Congratulations, your registration is complete!');

                // if (isNotNullUndefinedBlank(phoneNumber)) {
                //     sendVerificationCode();
                // }
                if (isNotNullUndefinedBlank(email)) {
                    sendVerificationCode();
                }
                else {
                    navigate("/completeprofile");
                }
            } else if (response.statusMessage !== "") {
                message.error(response.statusMessage);
                if (email !== "") {
                    storage.setEmailId(email);
                }
                // else if (phoneNumber !== "") {
                //     storage.setPhoneNumber(phoneNumber);
                //storage.setDialingCode(country.find(x => x.countryId === selectedCountry?.value)?.dialingCode ?? "");
                // }
                // navigate("/");
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            setLoading(false);
            message.error(error.message);
        }
    });

    const registerWithGoogle = () => {
        storage.setUserRoleId(userRoleId);
        window.location.href = googleAuthenticateUrl();
    };

    // const registerWithFacebook = () => {
    //     storage.setUserRoleId(userRoleId);
    //     window.location.href = facebookAuthenticateUrl();
    // };

    const getAutoGeneratePassword = () => {
        const password = onAutoGeneratePassword();
        form.setFieldValue("password", password);
        setPassword(password);
        setPasswordScore(checkPasswordStrength(password));
    };

    return (
        <div className="app-container">
            <Row className="min-h-screen">
                <Col span={12} className="hidden lg:block bg-black h-screen sticky top-0">
                    <div className='h-screen bg-login-background bg-no-repeat bg-cover flex justify-center items-center'>
                        <div>
                            <div className='flex justify-center w-full'>
                                <div className='w-72'>
                                    <img src={Images.triplogo} className='w-full' alt="triplogo" />
                                </div>
                            </div>
                            <div className='mt-20 flex justify-center'>
                                <img src={Images.travellogo} className='w-[335px] 2xl:w-[443px]' alt="travel-logo" />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={24} lg={12}>
                    <div className='block  lg:w-0 lg:h-0 lg:hidden'>
                        <div className='flex justify-center py-16'>
                            <img src={Images.mobilelogo} className='w-[140px] h-[60px] lg:w-[219px] lg:h-[96px]' alt="triplogo" />
                        </div>
                    </div>
                    <div className='flex justify-center items-center lg:h-screen'>
                        <div className="p-6 w-full max-w-md">
                            <div>
                                <p className="text-center text-[32px] font-bold  text-black">Create an account</p>
                                <p className='mb-6 text-center text-base font-medium'>Let's get you connected</p>
                                <div className='flex mb-3'>
                                    <p className='font-semibold mr-5'>I am</p>
                                    <Radio.Group onChange={onChange} value={userRoleId}>
                                        <Radio value={3} className='font-semibold'>Traveller</Radio>
                                        <Radio value={2} className='font-semibold'>Trip Assistant</Radio>
                                    </Radio.Group>
                                </div>
                                <Form
                                    layout="vertical"
                                    form={form}
                                    initialValues={{ email: '', password: '' }}
                                    onFinish={onRegister}
                                >
                                    <Form.Item
                                        className="font-semibold"
                                        //label="Email/Phone number"
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                //message: "Please input your E-mail/PhoneNumber!",
                                                message: "Please input your E-mail!",
                                            },
                                        ]}
                                    >
                                        {/* <Input
                                            addonBefore={
                                                !isPasswordRequired && phoneNumber ?
                                                    <Select
                                                        labelInValue
                                                        value={{ value: selectedCountry?.value, label: selectedCountry?.label }}
                                                        onChange={handleCountryCodeChange}
                                                        className="h-12 w-[90px] country-code"
                                                        popupClassName="dropdown-max-content"
                                                    >
                                                        {country ? country.map(x => (
                                                            <Option key={x.countryId} value={x.countryId}>

                                                                <img src={x.imageUrl} className='w-5' alt="Country Flag" />

                                                                {x.dialingCode} {x.countryName}
                                                            </Option>
                                                        )) : ""}
                                                    </Select> : ""
                                            }
                                            onChange={onChangeInputValue} autoFocus className="font-medium text-neutral-600 h-12 phone-input" placeholder="Enter your email/phonenumber"
                                            maxLength={
                                                (phoneNumber) ? 10 : undefined
                                            } /> */}
                                        <Input
                                            onChange={onChangeInputValue}
                                            autoFocus
                                            className="font-medium text-neutral-600 h-12"
                                            placeholder="Enter your email"
                                        />
                                    </Form.Item>
                                    {isPasswordRequired && (
                                        <Form.Item
                                            className="font-semibold auto-password"
                                            label={
                                                <div className="flex justify-between w-full">
                                                    <span>Password</span>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="mb-2 underline cursor-pointer select-none outline-none text-black text-xs border-none relative -bottom-3 hover:text-black"
                                                            onClick={() => {
                                                                getAutoGeneratePassword();
                                                                form.submit();
                                                            }}
                                                        >
                                                            Auto Generate Password
                                                        </button>
                                                    </div>
                                                </div>}
                                            name="password"
                                            rules={[{ required: true, message: 'Please input your password!' }]}
                                            validateTrigger="onBlur"
                                        >
                                            <Input.Password className="font-medium text-neutral-600 h-12" placeholder="Enter your password"
                                                value={password}
                                                onChange={inputChange} />
                                            <div className="flex -mx-1 mt-2">
                                                {Array.from({ length: 5 }).map((_, i) => (
                                                    <div key={i} className="w-1/5 px-1">
                                                        <div
                                                            className={`h-1 rounded-xl transition-colors ${i < passwordScore
                                                                ? passwordScore <= 2
                                                                    ? "bg-red-600"
                                                                    : passwordScore <= 3
                                                                        ? "bg-orange-400"
                                                                        : passwordScore <= 4
                                                                            ? "bg-yellow-400"
                                                                            : "bg-green-500"
                                                                : "bg-slate-300"
                                                                }`}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='text-end mt-1'>
                                                <span className={`${passwordScore !== 0 ? (passwordScore > 0 && passwordScore <= 2) ? 'text-red-600' :
                                                    passwordScore <= 3 ? 'text-orange-400' :
                                                        passwordScore <= 4 ? 'text-yellow-400'
                                                            : 'text-green-500' : 'text-slate-300'} 
                                                    text-10 font-semibold`} >
                                                    {passwordScore !== 0 ? (passwordScore > 0 && passwordScore <= 2 ? 'Low' : passwordScore <= 3 ? 'Weak' : passwordScore <= 4 ? 'Medium' : 'Strong') : ''}
                                                </span>
                                            </div>
                                        </Form.Item>
                                    )}
                                    <Form.Item>
                                        <Button htmlType='submit' loading={loading} className="h-14 w-full mt-6 text-xl bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out cursor-pointer">Create an account</Button>
                                    </Form.Item>
                                    <p className='text-center text-base'>Or continue with</p>
                                    <div className="gap-6 mt-5">
                                        <div className='flex justify-center'>
                                            <Button
                                                type="primary"
                                                className="bg-red-50 w-full h-10 text-black font-sora text-base font-semibold py-3.5 flex justify-center items-center outline-none hover:bg-rose focus-visible:bg-rose btn-hover-animation hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out cursor-pointer" onClick={registerWithGoogle}>
                                                <GooglePlusOutlined />
                                                <span>Google</span>
                                            </Button>
                                            {/* <Button
                                                type="primary"
                                                className="bg-blue-50 w-1/2 h-10 text-black font-sora text-base font-semibold py-3.5 flex justify-center items-center outline-none hover:bg-rose focus-visible:bg-rose btn-hover-animation hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out cursor-pointer" onClick={registerWithFacebook}>
                                                <FacebookFilled />
                                                <span>Facebook</span>
                                            </Button> */}
                                        </div>
                                    </div>
                                    <div className="mt-6 sm:!mt-10">
                                        <p className="text-base font-normal text-center text-black-russian">Have an Admin account?<span className="text-base font-semibold">
                                            <a href="/" className="outline-none cursor-pointer ml-1" onClick={() => { storage.clearPreviousUrlPath() }}>
                                                <span className="text-xs sm:text-base text-black font-semibold underline outline-none">Sign in!</span>
                                            </a>
                                        </span>
                                        </p>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Registration;