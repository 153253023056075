import { patch, get } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useUpdateGeneralSetting = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("generalsetting", data);
    }, options);
};

export const useFetchGeneralSetting = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("generalsetting?settingCode=");
    }, options);
};