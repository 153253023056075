import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Layout, Dropdown, Button, MenuProps } from "antd";
import Images from "../assets/image";
import { useNavigate } from "react-router-dom";

type MenuItemKey = string | number;

const App: React.FC = () => {
    const { Header } = Layout;
    const navigate = useNavigate();

    const handleMenuClick = ({ key }: { key: MenuItemKey }) => {
        if (key === '1') {
            window.localStorage.clear();
            navigate('/');
        }
    };

    const menuItems: MenuProps['items'] = [
        {
            key: '1',
            label: 'Logout',
        },
    ];

    const menu: MenuProps = {
        items: menuItems,
        onClick: handleMenuClick,
    };

    return (
        <Layout className="sticky top-0 z-10">
            <Header className="user-profile-btn flex items-center p-0 bg-black h-[75px]">
                <div className="trip-logo">
                    <div className="p-2 h-[73px]">
                        <img src={Images.triplogo} alt="header logo" className="h-full w-full object-contain" />
                    </div>
                </div>
                <div className="flex justify-between items-center w-full px-4">
                    <p className="text-white text-lg">Admin</p>
                    <div>
                        <Dropdown menu={menu} placement="bottomRight">
                            <Button
                                icon={<UserOutlined />}
                                className="bg-transparent border rounded-full hover:!bg-transparent hover:!border-white" />
                        </Dropdown>
                    </div>
                </div>
            </Header>
        </Layout>
    );
};

export default App;
