import { get, patch, post } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useFetchCities = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("city/list?stateId=" + data.stateId + "&countryId=" + data.countryId + "&searchFilter=" + data.searchFilter + "&isFavourite=" + data.isFavourite + "&isServiceable=" + data.isServiceable);
    }, options);
};

export const useAddCity = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (newCity: any) => {
        return await post("city", newCity);
    }, options);
};

export const useDeleteCity = (options: any) => {
    return useMutation(async (cityId) => {
        return await patch(`city/${cityId}/delete`, {});
    }, options);
};