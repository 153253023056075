import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Input, message, Modal, Spin } from "antd";
import { EditOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { PlusOutlined } from '@ant-design/icons';
import { Col, Drawer, Form, Row, Select, } from 'antd';
import Column from "antd/es/table/Column";
import { useFetchCountry } from "../services/countries.service";
import { useAddState, useDeleteState, useFetchStates } from "../services/states.service";
import { IState } from "../types/states";
import { compareObjects, isNotNullUndefined, isNotNullUndefinedBlank } from "../helpers/common";
import { storage } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import { eventEmitter } from "../helpers/eventemitter";

const States: React.FC = () => {
    const { Search } = Input;
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const isAddOpen = useRef(false);
    const [countries, setCountry] = useState<any>([]);
    const [states, setStates] = useState<any>([]);
    const [countryOptions, setCountryOptions] = useState<any>([]);
    const [editingState, setEditingState] = useState<IState | null>(null);
    const [modalType, setModalType] = useState<'delete' | 'update' | 'add' | ''>('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [stateId, setStateId] = useState();
    const [formUpdateValues, setFormUpdateValues] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const formInitialValues = useRef<any>({});
    const formUpdatedValues = useRef<any>({});
    const searchValue = useRef<string>("");

    useEffect(() => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        if (userRoleId !== 1) {
            navigate("/");
        }
        else {
            setIsLoading(true);
            getStatesList("", "");
            getCountry.mutate({
                searchFilter: "",
                isServiceable: ""
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showDrawer = (isEditState: boolean) => {
        isAddOpen.current = !isEditState;
        if (isAddOpen.current) {
            setEditingState(null);
            form.resetFields();
        }
        eventEmitter.emit("clickableChange", true);
        setOpen(true);
    };

    const onClose = () => {
        eventEmitter.emit("clickableChange", false);
        form.resetFields();
        setOpen(false);
        setTimeout(() => { isAddOpen.current = false; }, 100);
    };

    const onEditState = (record: any) => {
        setEditingState(record);
        form.setFieldsValue({
            countryId: record.countryId,
            countryName: record.countryName,
            stateId: record.stateId,
            stateName: record.stateName
        });
        formInitialValues.current = { stateName: record.stateName, countryId: record.countryId }
        showDrawer(true);
    };

    const getCountry = useFetchCountry({
        onSuccess: (response: any) => {
            if (response != null && response !== undefined) {
                setCountry(response);
                setCountryOptions(response.map((data: any) => {
                    return {
                        value: data.countryName.toLowerCase(),
                        label: data.countryName.replace(/\w\S*/g, (txt: any) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
                    };
                }));
            } else {
                message.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });


    const getStatesList = async (countryId: string, search: string) => {
        setIsLoading(true);
        getStatesMutation.mutate({
            countryId: countryId,
            searchFilter: search
        });
    }

    const getStatesMutation = useFetchStates({
        onSuccess: (response: any) => {
            setStates(response.data);
            setIsLoading(false);
        },
        onError: (error) => {
            alert(error.message);
            setIsLoading(false);
        },
    });

    const addState = useAddState({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                setIsLoading(false);
                getCountry.mutate({
                    searchFilter: ""
                });
                let successMessage = isAddOpen.current ? "State has been added successfully!" : "State has been updated successfully!";
                message.success(successMessage);
            } else {
                setIsLoading(false);
                message.error(response.statusMessage);
            }
            onClose();
            form.resetFields();
        },
        onError: (error) => {
            setIsLoading(false);
            alert(error.message);
        },
    });

    const deleteState = useDeleteState({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                getStatesList("", "");
                message.success("State has been deleted successfully!");
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            if (isNotNullUndefined(error.response) && isNotNullUndefined(error.response.data) && isNotNullUndefinedBlank(error.response.data.message)) {
                message.error(error.response.data.message);
            } else {
                message.error("An unexpected error occurred.");
            }
        },
    });

    const onFinish = (values: any) => {
        setModalType(((isAddOpen.current) ? 'add' : 'update'));
        if (modalType === 'update') {
            const changedCountryId = countries.find((x: { countryName: string; }) => x.countryName.toLowerCase() === values.countryName.toLowerCase())?.countryId ?? "";
            formUpdatedValues.current = { stateName: values.stateName, countryId: changedCountryId }
            if (compareObjects(formInitialValues.current, formUpdatedValues.current)) {
                onClose();
                return;
            }
        }
        setIsConfirmModalVisible(true);
        setFormUpdateValues(values);
    };

    const onDeleteState = (record: any) => {
        setModalType('delete');
        setIsConfirmModalVisible(true);
        setFormUpdateValues({ stateName: record.stateName });
        setStateId(record.stateId);
    }

    const handleSave = async () => {
        try {
            if (modalType === 'delete') {
                deleteState.mutate(stateId);
            }
            else if (modalType === 'update' && editingState) {
                setIsLoading(true);
                const editedData = { ...editingState, ...formUpdateValues };
                addState.mutate({ stateID: editedData.stateId, countryID: editedData.countryId, stateName: editedData.stateName });
            }
            else if (modalType === 'add') {
                setIsLoading(true);
                let countryId = countries.find((x: any) => x.countryName.toLowerCase() === formUpdateValues.countryName.toLowerCase())?.countryId;
                addState.mutate({ stateID: null, countryID: countryId, stateName: formUpdateValues.stateName });
            }
            setIsConfirmModalVisible(false);
        } catch (error) {
            message.error('Failed to save');
        }
    };

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'delete') {
            message = "Confirm Deletion!";
        } else if (modalType === 'update') {
            message = "Confirm Update!";
        }
        else if (modalType === 'add') {
            message = "Confirm Add!";
        }
        return message;
    }

    const handleCancel = () => {
        setIsLoading(false);
        setIsConfirmModalVisible(false);
    };

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === 'delete') {
            modalContent = (
                <p>Are you sure you want to delete <b>{formUpdateValues.stateName}</b> State's Delete request? Once deleted, this state will not be recovered. Do you wish to proceed?</p>
            );
        } else if (modalType === 'update') {
            modalContent = (
                <p>Are you sure you want to update <b>{formUpdateValues.stateName}</b> State's Update request? Once updated, this state can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        else if (modalType === 'add') {
            modalContent = (
                <p>Are you sure you want to add <b>{formUpdateValues.stateName}</b> State's Add request? Once added, this state can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        return modalContent;
    }

    const onSearch = (value: any) => {
        getStatesList("", value);
        searchValue.current = value;
    };

    return (
        <div>
            <div className="flex justify-between align-middle pb-5">
                <h2 className="text-2xl font-semibold text-black">States/Province </h2>
                <div className="flex justify-between gap-5">
                    <div className="w-80 max-w-80">
                        <Search
                            placeholder="Search state/province"
                            size="large"
                            enterButton
                            className="search-field"
                            onSearch={onSearch}
                        />
                    </div>
                    <Button className="h-10 bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out" onClick={() => showDrawer(false)} icon={<PlusOutlined />}>
                        Add State / Province
                    </Button>
                </div>
            </div>
            <Drawer
                title={((isAddOpen.current) ? 'Add New' : 'Edit') + ' State / Province'}
                width={370}
                onClose={onClose}
                loading={isLoading}
                mask={false}
                open={open}>
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="countryId"
                                label="Country ID"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="countryName"
                                label="Country"
                                rules={[{ required: true, message: 'Please select a Country' }]}>
                                <Select placeholder="Please select a Country" size="large" options={countryOptions}>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="stateId"
                                label="State ID"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="stateName"
                                label="State / Province"
                                rules={[{ required: true, message: 'Please enter your state / province name' }]}>
                                <Input size="large" placeholder="Please enter your state / province" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Space className="w-full justify-end">
                        <Button onClick={onClose} className="hover:!text-black hover:!border-black">Cancel</Button>
                        <Button htmlType="submit" className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">{(isAddOpen.current) ? 'Add' : 'Update'}</Button>
                    </Space>
                </Form>
            </Drawer>
            <Table dataSource={states} className="userlist-table" bordered
                locale={{
                    emptyText: isLoading ? <Spin /> : (
                        <div className="ant-empty">
                            <div className="ant-empty-image">
                                <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                        <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                        <g fillRule="nonzero" stroke="#d9d9d9">
                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                            <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="ant-empty-description">No data</div>
                        </div>
                    )
                }}>
                <Column title="State / Province Name" dataIndex="stateName" key="stateName" align="center" />
                <Column title="Country Name" dataIndex="countryName" key="countryName" align="center" />
                <Column
                    align="center"
                    title="Action"
                    key="action"
                    render={(_: any, record: any) => (
                        <Space size="middle">
                            <Button onClick={() => onEditState(record)} icon={<EditOutlined />} className="hover:!border-black hover:!text-black"></Button>
                            <Button onClick={() => onDeleteState(record)} icon={<DeleteOutlined />} className="hover:!border-black hover:!text-black"></Button>
                        </Space>
                    )}
                />
            </Table>

            <Modal
                title={<span className="custom-modal-title">{getModalTitle()}</span>}
                open={isConfirmModalVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                footer={[
                    <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                        Cancel
                    </Button>,
                    <Button key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                        Confirm
                    </Button>,
                ]}
            >
                {getModelContent()}
            </Modal>
        </div>
    );
}
export default States;