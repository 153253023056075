import { get, patch, post } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useCompleteAgentProfile = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("agent/complete-profile", data);
    }, options);
};

export const useUpdateAgentStatus = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("agent/status/update?agentId=" + data.agentId + "&statusCode=" + data.statusCode + "&reason=" + data.reason, data);
    }, options);
};

export const useGetDocumentTypes = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("agent/documenttypes");
    }, options);
};

export const useAddAgentDocument = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("agent/document", data);
    }, options);
};

export const useApproveRejectAgent = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("agent/approve-reject", data);
    }, options);
};

export const useFetchAgentList = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("agent/list?statusId=" + data.statusId + "&pageNumber=" + data.pageNumber + "&pageSize=" + data.pageSize + "&searchFilter=" + data.searchFilter);
    }, options);
};

export const useFetchAgentStatuses = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("agent/statuses");
    }, options);
};

export const useFetchAgentProfileInfo = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (agentId: string) => {
        return await get(`agent/profile-info?agentId=${agentId}`);
    }, options);
};
