import { useEffect, useRef, useState } from 'react';
import { Row, Col, message } from 'antd';
import Images from '../assets/image/index';
import { Button, Form, Input } from 'antd';
import { storage } from '../utils/storage';
import { useAuthenticate } from '../services/login.service';
import IResponse from '../types/response';
import { useNavigate } from 'react-router-dom';
//import { useFetchCountry } from '../services/countries.service';
//import { ICountry } from '../types/countries';
import { isNotNullUndefined, isNotNullUndefinedBlank, statusWiseRedirectToPage } from '../helpers/common';
import { useFetchUser } from '../services/user.service';

const Login = () => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    //const { Option } = Select;
    const [loading, setLoading] = useState(false);
    //const [selectedCountry, setSelectedCountry] = useState<any>();
    //const [country, setCountry] = useState<ICountry[]>([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    //const [phoneNumber, setPhoneNumber] = useState('');
    const [isEmailTyped, setIsEmailTyped] = useState(false);
    const lastVisitedUrl = useRef<string>('');

    useEffect(() => {
        const lastUrl = (storage.getPreviousUrlPath() ?? "");
        const refreshToken = (storage.getRefreshToken() ?? "");
        const token = (storage.getToken() ?? "");
        const userRoleId = (storage.getUserRoleId() ?? "");
        const codeType = (storage.getCodeType() ?? "");
        const emailId = (storage.getEmailId() ?? "");
        const countryId = (storage.getCountryId() ?? "");
        const phoneNumber = (storage.getPhoneNumber() ?? "");
        const currentPath = window.location.pathname;

        const isLoggedIn = (
            isNotNullUndefinedBlank(refreshToken) &&
            isNotNullUndefinedBlank(token) &&
            isNotNullUndefinedBlank(userRoleId)
        );

        const isAuthenticationPage = (
            isNotNullUndefinedBlank(codeType) &&
            (isNotNullUndefinedBlank(emailId) || (isNotNullUndefinedBlank(countryId) && isNotNullUndefinedBlank(phoneNumber))) &&
            (lastUrl === '/forgotpassword' || lastUrl === '/registration')
        );

        if (isNotNullUndefinedBlank(lastUrl) && !isAuthenticationPage) {
            lastVisitedUrl.current = lastUrl;
        }
        if (isLoggedIn && currentPath === '/') {
            if (lastVisitedUrl.current) {
                navigate(lastVisitedUrl.current);
                storage.setPreviousUrlPath(lastVisitedUrl.current);
            } else {
                navigate(userRoleId === '1' ? '/attendants' : statusWiseRedirectToPage());
            }
        } else if (lastUrl === '/registration') {
            if (emailId !== "") {
                setIsEmailTyped(true);
                setEmail(emailId);
                form.setFieldValue("email", emailId);
            }
            // else if (phoneNumber !== "") {
            //     setIsEmailTyped(false);
            //     setSelectedCountry(countryId);
            //     setPhoneNumber(phoneNumber);
            //     form.setFieldValue("email", phoneNumber);
            // }
        } else {
            window.localStorage.clear();
        }
        //fetchCountry();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    // const fetchCountry = async () => {
    //     fetchCountryMutation.mutate({
    //         searchFilter: "",
    //         isServiceable: ""
    //     });
    // };

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                storage.setUserRoleId(response.data.userRoleId);
                storage.setUserStatusId(response.data.statusId);
                storage.setUserId(response.data.userId);
                if (response.data.email !== "") {
                    storage.setEmailId(response.data.email);
                }
                // if (response.data.phoneNo !== "") {
                //     storage.setPhoneNumber(response.data.phoneNo);
                //     storage.setDialingCode(country.find(x => x.countryId === response.data.countryId)?.dialingCode ?? "");
                // }
                storage.setCountryId(response.data.countryId);
                navigate(statusWiseRedirectToPage());
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    // const fetchCountryMutation = useFetchCountry({
    //     onSuccess: (response: IResponse) => {
    //         if (response != null && response !== undefined) {
    //             response.data = response;
    //             setCountry(response.data);
    //             const defaultCountry = response.data.find((x: { countryId: number; }) => x.countryId === 1);
    //             if (defaultCountry) {
    //                 const defaultOption = {
    //                     value: defaultCountry.countryId,
    //                     label: (
    //                         <div style={{ display: 'flex', alignItems: 'center' }}>
    //                             <img src={defaultCountry.imageUrl} className='w-5' alt="Country Flag" style={{ marginRight: 8 }} />
    //                             {defaultCountry.dialingCode} {defaultCountry.countryName}
    //                         </div>
    //                     )
    //                 };
    //                 const option = { value: defaultOption.value, label: defaultOption.label.props.children };
    //                 handleCountryCodeChange(option);
    //             }
    //         }
    //     },
    //     onError: (error: any) => {
    //         alert(error.message);
    //     }
    // });

    const requestForgotPassword = () => {
        navigate("/forgotpassword");
    };

    const onChangeInputValue = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        if (value.length >= 3) {
            if (/^[0-9]+$/.test(value)) {
                //setPhoneNumber(value);
                setEmail('');
                setIsEmailTyped(false);
            } else {
                setEmail(value);
                //setPhoneNumber('');
                setIsEmailTyped(true);
            }
        } else if (value.length === 0) {
            setEmail('');
            //setPhoneNumber('');
            setIsEmailTyped(false);
        }
    };

    const authenticateMutation = useAuthenticate({
        onSuccess: (response: IResponse) => {
            setLoading(false);
            if (response.statusCode === 200) {
                storage.setToken(response.data.token);
                storage.setRefreshToken(response.data.refreshToken);
                storage.setUserRoleId(response.data.userRoleId);
                if (response.data.userRoleId === 1) {
                    message.success('You have logged in successfully!');
                    navigate("/attendants");
                }
                else {
                    fetchUser();
                }
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            setLoading(false);
            message.error(error.message);
        }
    });

    const onAuthenticate = async () => {
        if (isNotNullUndefinedBlank(email)) {
            authenticateMutation.mutate({
                userEmail: email,
                password: password
            });
        }
        // else if (isNotNullUndefinedBlank(phoneNumber)) {
        //     if (selectedCountry.value <= 0) {
        //         message.error("Please choose a country.");
        //         return false;
        //     }
        //     else {
        //         getUserByPhoneNo(phoneNumber);
        //     }
        // }
        setLoading(true);
    };

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    // const sendVerificationCode = async () => {
    //     sendVerificationCodeMutation.mutate({
    //         userId: storage.getUserId() ?? "",
    //         userEmail: "",
    //         phoneNumber: phoneNumber,
    //         phoneNoDialingCode: selectedCountry.label[1] ?? storage.getDialingCode()
    //     });
    // };

    // const getUserByPhoneNo = async (phoneNo: string) => {
    //     getUserByPhoneNoMutation.mutate({ phoneNumber: phoneNo });
    // }

    // const getUserByPhoneNoMutation = useFetchUserByPhoneNo({
    //     onSuccess: (response: any) => {
    //         setLoading(false);
    //         if (response.statusCode === 200) {
    //             storage.setUserRoleId(response.data?.userRoleId);
    //             if (response.data.isPhoneNoVerified === false && isNotNullUndefinedBlank(response.data.phoneNo) && isNotNullUndefinedBlank(response.data.email)) {
    //                 message.error('Your mobile number is not verified. Please log in with your verified email address and verify your mobile number from the profile page.');
    //             }
    //            else if (isNotNullUndefined(response.data) && response.data?.userRoleId === 1) {
    //                 message.error('Please log in again using an admin email address & passowrd.');
    //             } else {
    //                 storage.setUserId((response.data.userId));
    //                 storage.setUserRoleId(response.data?.userRoleId);
    //                 storage.setUserStatusId(response.data.statusId);
    //                 storage.setDialingCode(response.data.phoneNoDialingCode);
    //                 sendVerificationCode();
    //             }
    //         }
    //         else {
    //             message.error(response.statusMessage);
    //         }
    //     },
    //     onError: (error) => {
    //         setLoading(false);
    //         message.error(error.message);
    //     }
    // });

    // const sendVerificationCodeMutation = useSendVerificationCode({
    //     onSuccess: (response: any) => {
    //         setLoading(false);
    //         if (response.statusCode === 200) {
    //             storage.setCodeType("sms");
    //             storage.setPhoneNumber(phoneNumber);
    //             storage.setCountryId(selectedCountry.value ?? 0);
    //             storage.setDialingCode(country.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "");
    //             message.success("OTP has been sent successfully.");
    //             navigate('/authorization');
    //         }
    //         else {
    //             message.error(response.statusMessage);
    //         }
    //     },
    //     onError: (error) => {
    //         setLoading(false);
    //         message.error(error.message);
    //     }
    // });

    // const handleCountryCodeChange = (option: any) => {
    //     if (Array.isArray(option.label)) {
    //         const slicedLabel = option.label.slice(0, 2);
    //         const modifiedOption = { ...option, label: slicedLabel };
    //         setSelectedCountry(modifiedOption);
    //     } else {
    //         setSelectedCountry(option);
    //     }
    // };

    return (
        <div>
            <div className="app-container">
                <Row className="min-h-screen">
                    <Col span={12} className="hidden lg:block bg-black h-screen">
                        <div className='h-screen bg-login-background bg-no-repeat bg-cover flex justify-center items-center'>
                            <div>
                                <div className='flex justify-center w-full'>
                                    <div className='w-72'>
                                        <img src={Images.triplogo} className='w-full' alt="triplogo" />
                                    </div>
                                </div>
                                <div className='mt-20 flex justify-center'>
                                    <img src={Images.travellogo} className='w-[335px] 2xl:w-[443px]' alt="travel-logo" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={24} lg={12}>
                        <div className="block  lg:w-0 lg:h-0 lg:hidden">
                            <div className="flex justify-center py-16">
                                <img
                                    src={Images.mobilelogo}
                                    className="w-[140px] h-[60px] lg:w-[219px] lg:h-[96px]"
                                    alt="triplogo"
                                />
                            </div>
                        </div>
                        <div className="flex justify-center items-center lg:h-screen">
                            <div className="p-6 w-full max-w-md">
                                <div>
                                    <p className="text-center text-[32px] font-extrabold mb-6 text-black">Welcome to Login</p>
                                    <Form
                                        layout="vertical"
                                        form={form}
                                        onFinish={onAuthenticate}
                                    >
                                        <Form.Item
                                            className="font-semibold"
                                            label="Email"
                                            name="email"
                                            id="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input your E-mail!",
                                                },
                                            ]}
                                        >
                                            {/* <Input
                                                addonBefore={
                                                    !isEmailTyped && phoneNumber ?
                                                        <Select
                                                            labelInValue
                                                            value={{ value: selectedCountry?.value, label: selectedCountry?.label }}
                                                            onChange={handleCountryCodeChange}
                                                            className="h-12 w-24 country-code"
                                                            popupClassName="dropdown-max-content"
                                                        >
                                                            {country ? country.map(x => (
                                                                <Option key={x.countryId} value={x.countryId}>

                                                                    <img src={x.imageUrl} className='w-5' alt="Country Flag" />

                                                                    {x.dialingCode} {x.countryName}
                                                                </Option>
                                                            )) : ""}
                                                        </Select> : ""
                                                }
                                                onChange={onChangeInputValue} autoFocus className="font-medium text-neutral-600 h-12 phone-input" placeholder="Enter your email" /> */}
                                            <Input
                                                onChange={onChangeInputValue}
                                                autoFocus
                                                className="font-medium text-neutral-600 h-12"
                                                placeholder="Enter your email"
                                            />
                                        </Form.Item>
                                        {
                                            isEmailTyped ? <Form.Item
                                                className="font-semibold"
                                                label="Password"
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your password!",
                                                    },
                                                ]}
                                            >
                                                <Input.Password onChange={(e) => setPassword(e.target.value)} className="font-medium text-neutral-600 h-12" placeholder="Enter your password" />
                                            </Form.Item>
                                                : ""
                                        }
                                        <div className='w-full flex justify-end'>
                                            <p className="text-sm font-semibold cursor-pointer text-[#000] -mt-4 block" onClick={requestForgotPassword} >
                                                Forgot password?
                                            </p>
                                        </div>
                                        <Form.Item>
                                            <Button
                                                htmlType="submit"
                                                className="h-14 w-full mt-6 text-xl bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black"
                                                loading={loading}>
                                                Let’s get started
                                            </Button>
                                        </Form.Item>
                                        <div className="mt-6 sm:!mt-10">
                                            <p className="text-base font-normal text-center text-black-russian">
                                                Create an Attendant or Traveller Account
                                                <span className="text-base font-semibold">
                                                    <a
                                                        href="/registration"
                                                        className="outline-none cursor-pointer ml-1"
                                                    >
                                                        <span className="text-xs sm:text-base text-black font-semibold underline outline-none">
                                                            Sign up!
                                                        </span>
                                                    </a>
                                                </span>
                                            </p>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Login;
