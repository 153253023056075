import triplogo from "./trip-logo.png";
import travellogo from "./travel-logo.png";
import loginbackground from "./login-background.png";
import mobilelogo from "./mobile-logo.png";
import imageedit from "./image-edit.svg";
import userprofile from "./user-profile.jpg";
import app from "./app.png";
import googleplay from "./googleplay.png";
import smalllogo from "./smalllogo.png";
import email from "./email.png";
import language from "./language.png";
import airport from "./airport.png";
import malefemale from "./malefemale.png";
import address from "./address.png";
import phone from "./phone.png";
import calendar from "./calendar.png";
import cities from "./cities.png";
import port from "./port.png";
import arrivalport from "./arrival-port.png";
import people from "./people.png";
import righticon from "./right-icon.png";
import paymenthistory from "./payment-history.png";
import disablelocation from "./disable-location.png";
import chat from "./chat.png";
import nochat from "./nochat.png";
import deleteicon from "./delete.png";

interface ImageType {
    triplogo: string;
    travellogo: string;
    loginbackground: string;
    mobilelogo: string;
    imageedit: string;
    userprofile: string;
    app: string;
    googleplay: string;
    smalllogo: string;
    email: string;
    language: string;
    airport: string;
    malefemale: string;
    address: string;
    phone: string;
    calendar: string;
    cities: string;
    port: string;
    arrivalport: string;
    people: string;
    righticon: string;
    paymenthistory: string;
    disablelocation: string;
    chat: string;
    nochat: string;
    deleteicon: string;
}

const Images: ImageType = {
    triplogo: triplogo,
    travellogo: travellogo,
    loginbackground: loginbackground,
    mobilelogo: mobilelogo,
    imageedit: imageedit,
    userprofile: userprofile,
    app: app,
    googleplay: googleplay,
    smalllogo: smalllogo,
    email: email,
    language: language,
    airport: airport,
    malefemale: malefemale,
    address: address,
    phone: phone,
    calendar: calendar,
    cities: cities,
    port: port,
    arrivalport: arrivalport,
    people: people,
    righticon: righticon,
    paymenthistory: paymenthistory,
    disablelocation: disablelocation,
    chat: chat,
    nochat: nochat,
    deleteicon: deleteicon
}
export default Images