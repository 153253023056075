import React, { ChangeEvent, useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import Images from '../assets/image/index';
import { Button, Form, Input } from 'antd';
import { checkPasswordStrength } from '../helpers/passwordstrength';
import { useNavigate } from 'react-router-dom';
import { useFetchUser, useSetPassword } from '../services/user.service';
import { storage } from '../utils/storage';
import { isNotNullUndefined, isNotNullUndefinedBlank, onAutoGeneratePassword } from '../helpers/common';
import IResponse from '../types/response';

const Resetpassword = () => {
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const [userId, setUserID] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordScore, setPasswordScore] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let token = storage.getToken() ?? "";
        if (isNotNullUndefinedBlank(token)) {
            storage.setPreviousUrlPath(window.location.pathname);
            fetchUser();
        }
        else {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                setUserID(response.data.userId);
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const getAutoGeneratePassword = () => {
        const password = onAutoGeneratePassword();
        form.setFieldValue("password", password);
        setPassword(password);
        form.setFieldValue("confirm", password);
        setConfirmPassword(password);
        setPasswordScore(checkPasswordStrength(password));
    };

    const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "password") {
            setPasswordScore(checkPasswordStrength(value));
            setPassword(value);
        }
        else if (name === "confirm") {
            setPasswordScore(checkPasswordStrength(value));
            setConfirmPassword(value);
        }
    };

    const onSetPassword = async () => {
        const emailId = storage.getEmailId();
        if (!isNotNullUndefinedBlank(emailId) || !isNotNullUndefinedBlank(password)) {
            return;
        }
        if (password !== confirmPassword) {
            message.error("The two passwords that you entered do not match!");
            return;
        }
        const param = {
            "userId": userId,
            "userEmail": storage.getEmailId(),
            "password": password
        }
        setIsLoading(true);
        setPasswordMutation.mutate(param);
    };

    const setPasswordMutation = useSetPassword({
        onSuccess: (response) => {
            if (response.statusCode === 200) {
                setIsLoading(false);
                message.success(response.statusMessage);
                window.localStorage.clear();
                navigate('/');
            }
            else {
                setIsLoading(false);
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        }
    });

    return (
        <div>
            <div className="app-container">
                <Row>
                    <Col span={12} className="hidden lg:block bg-black h-screen">
                        <div className='h-screen bg-login-background bg-no-repeat bg-cover flex justify-center items-center'>
                            <div>
                                <div className='flex justify-center w-full'>
                                    <div className='w-72'>
                                        <img src={Images.triplogo} className='w-full' alt="triplogo" />
                                    </div>
                                </div>
                                <div className='mt-20 flex justify-center'>
                                    <img src={Images.travellogo} className='w-[335px] 2xl:w-[443px]' alt="travel-logo" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={24} lg={12}>
                        <div className='block  lg:w-0 lg:h-0 lg:hidden'>
                            <div className='flex justify-center py-16'>
                                <img src={Images.mobilelogo} className='w-[140px] h-[60px] lg:w-[219px] lg:h-[96px]' alt="triplogo" />
                            </div>
                        </div>
                        <div className="flex justify-center items-center lg:h-screen">
                            <div className="p-6 w-full max-w-md">
                                <p className="text-center text-[32px] font-extrabold mb-6 text-black">Reset your password</p>
                                <Form
                                    layout="vertical"
                                    form={form}
                                    className="space-y-4"
                                    onSubmitCapture={onSetPassword}
                                >
                                    <div>
                                        <Form.Item className="font-semibold auto-password" name="password" label={
                                            <div className="flex justify-between w-full">
                                                <span>Password*</span>
                                                <button
                                                    className="mb-2 underline cursor-pointer select-none outline-none text-black text-xs relative -bottom-3 hover:text-black"
                                                    onClick={getAutoGeneratePassword}>
                                                    Auto Generate Password
                                                </button>
                                            </div>}
                                            rules={[{ required: true, message: 'Please input your password!' }]}>
                                            <Input.Password className="font-medium text-neutral-600 h-12"
                                                id='ddlPassword'
                                                placeholder="Enter your password"
                                                value={password}
                                                name="password"
                                                onChange={inputChange}
                                            />
                                        </Form.Item>
                                    </div>
                                    <Form.Item className="font-semibold" label="Confirm Password*" name="confirm" rules={[
                                        { required: true, message: 'Please confirm your password!' },
                                        // eslint-disable-next-line no-empty-pattern
                                        ({ }) => ({
                                            validator(_, value) {
                                                if (!value || (document.getElementsByName("password") as any)[0].value === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}>
                                        <Input.Password className="font-medium text-neutral-600 h-12"
                                            id='ddlConfirmPassword'
                                            placeholder="Enter your confirm password"
                                            value={confirmPassword}
                                            name="confirm"
                                            onChange={inputChange}
                                        />
                                    </Form.Item>
                                    <div className="flex -mx-1 mt-2">
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <div key={i} className="w-1/5 px-1">
                                                <div
                                                    className={`h-1 rounded-xl transition-colors ${i < passwordScore
                                                        ? passwordScore <= 2
                                                            ? "bg-red-600"
                                                            : passwordScore <= 2
                                                                ? "bg-orange-400"
                                                                : passwordScore <= 4
                                                                    ? "bg-yellow-400"
                                                                    : "bg-green-500"
                                                        : "bg-slate-300"
                                                        }`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className='text-end mt-1'>
                                        <span className={`${passwordScore !== 0 ? (passwordScore > 0 && passwordScore <= 2) ? 'text-red-600' :
                                            passwordScore <= 3 ? 'text-orange-400' :
                                                passwordScore <= 4 ? 'text-yellow-400'
                                                    : 'text-green-500' : 'text-slate-300'} 
                                                    text-10 font-semibold`} >
                                            {passwordScore !== 0 ? (passwordScore > 0 && passwordScore <= 2 ? 'Low' : passwordScore <= 3 ? 'Weak' : passwordScore <= 4 ? 'Medium' : 'Strong') : ''}
                                        </span>
                                    </div>
                                    <Form.Item>
                                        <Button htmlType="submit" className="h-14 w-full mt-6 text-xl bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black"
                                            loading={isLoading}>Reset password
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Resetpassword;