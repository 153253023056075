import { useState, useEffect } from "react";

export const useTimer = () => {
  const timerSeconds = 30;
  const [resendTimer, setResendTimer] = useState(timerSeconds);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  const resetTimer = () => {
    setResendTimer(timerSeconds);
  };

  return {
    resendTimer,
    resetTimer,
  };
};