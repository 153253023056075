import React, { useEffect, useState } from "react";
import { Button, Input, Col, Form, message } from "antd";
import { useUpdateGeneralSetting, useFetchGeneralSetting } from "../services/generalsetting.service";
import { IPlatformFee } from "../types/generalsetting";
import IResponse from "../types/response";
import { isNotNullUndefined, isNotNullUndefinedBlank } from "../helpers/common";
import { storage } from "../utils/storage";
import { useNavigate } from 'react-router-dom';

const GeneralSetting: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [settingData, setSettingData] = useState<IPlatformFee[]>([]);

    useEffect(() => {
        let token = storage.getToken() ?? "";
        if (!isNotNullUndefinedBlank(token)) {
            navigate("/");
            return;
        }
        fetchGeneralSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchGeneralSetting = async () => {
        fetchGeneralSettingMutation.mutate({});
    };

    const fetchGeneralSettingMutation = useFetchGeneralSetting({
        onSuccess: (response: IResponse) => {
            if (response && response.data) {
                const data = Array.isArray(response.data) ? response.data : [response.data];
                setSettingData(data);
            } else {
                message.error("Unexpected response format");
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        },
    });

    const updateGeneralSetting = useUpdateGeneralSetting({
        onSuccess: (response) => {
            if (isNotNullUndefined(response) && isNotNullUndefined(response.data) && response.statusCode === 200) {
                message.success(response.statusMessage);
            } else {
                message.success(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const handleInputChange = (index: number, newValue: string) => {
        const updatedData = [...settingData];
        updatedData[index] = {
            ...updatedData[index],
            settingValue: newValue,
        };
        setSettingData(updatedData);
    };

    const onFinish = (values: any) => {
        const updatedSettings = settingData.map((item) => ({
            settingId: item.settingId,
            settingName: item.settingName,
            settingValue: item.settingValue
        }));

        updateGeneralSetting.mutate(updatedSettings);
    };

    return (
        <div>
            <div className="flex justify-between align-middle pb-5">
                <h2 className="text-2xl font-semibold text-black">General Setting</h2>
                <div className="flex justify-between gap-5"></div>
            </div>
            <div className="setting-input">
                <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{}}>
                    {settingData.map((item, index) => (
                        <React.Fragment key={item.settingId}>
                            <Form.Item name={item.settingId} hidden initialValue={item.settingValue}>
                                <Input />
                            </Form.Item>

                            <Form.Item label={item.settingName} className="w-full mt-[18px] mb-0">
                                <Col span={4} className="flex items-center gap-3">
                                    <Input
                                        className="h-10 w-full"
                                        value={item.settingValue}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                    />
                                    <Button
                                        htmlType="submit"
                                        className="bg-black text-white h-10 hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out">
                                        Save
                                    </Button>
                                </Col>
                            </Form.Item>
                        </React.Fragment>
                    ))}
                </Form>
            </div>
        </div>
    );
};

export default GeneralSetting;