import { ReactNode, useEffect } from "react";
import Header from "./header";
import Sider from "antd/es/layout/Sider";
import { Breadcrumb, Layout, Menu } from "antd";
import {
  UnorderedListOutlined,
  VideoCameraOutlined,
  FlagOutlined,
  EnvironmentOutlined,
  ShopOutlined,
  SettingOutlined,
  WechatWorkOutlined
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { isNotNullUndefinedBlank } from "../helpers/common";
import { storage } from "../utils/storage";

type MainLayoutProps = {
  children: ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {

  const navigate = useNavigate();

  useEffect(() => {
    storage.setPreviousUrlPath(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const urlPathToKeyMap: any = {
    '/attendants': '1',
    '/travellers': '2',
    '/mostvisitedplaces': '3',
    '/countries': '4',
    '/states': '5',
    '/cities': '6',
    //'/ports': '7',
    '/trips': '8',
    '/chats': '9',
    '/settings': '10'
  };

  const getKeyFromUrlPath = (path: string) => {
    return urlPathToKeyMap[path] || '1'; // Default to '1' if path not found
  };

  const keyToUrlPathMap = Object.fromEntries(
    Object.entries(urlPathToKeyMap).map(([path, key]) => [key, path])
  );

  const defaultSelectedKey = getKeyFromUrlPath(window.location.pathname);

  const redirectToMenuPage = (item: any) => {
    const path = keyToUrlPathMap[item.key];
    if (isNotNullUndefinedBlank(path)) {
      navigate(path);
    }
  };

  return (
    <Layout>
      <Header></Header>
      <Layout>
        <Sider width={210}>
          <Menu
            onClick={(e) => { redirectToMenuPage(e) }}
            rootClassName="mainLayoutMenu"
            className="h-[calc(100vh-75px)] min-h-full sidebar-menu-active text-base fixed w-[210px]"
            theme="light"
            mode="inline"
            defaultSelectedKeys={[defaultSelectedKey]}
            items={[
              {
                key: '1',
                icon: <UnorderedListOutlined />,
                label: 'Attendants',
              },
              {
                key: '2',
                icon: <UnorderedListOutlined />,
                label: 'Travellers',
              },
              {
                key: '3',
                icon: <VideoCameraOutlined />,
                label: 'Most Visited Places',
              },
              {
                key: '4',
                icon: <FlagOutlined />,
                label: 'Countries',
              },
              {
                key: '5',
                icon: <EnvironmentOutlined />,
                label: 'States/Province',
              },
              {
                key: '6',
                icon: <ShopOutlined />,
                label: 'Cities',
              },
              // {
              //   key: '7',
              //   icon: <DockerOutlined />,
              //   label: 'Ports',
              // },
              {
                key: '8',
                icon: <UnorderedListOutlined />,
                label: 'Trip List',
              },
              {
                key: '9',
                icon: <WechatWorkOutlined />,
                label: 'Chats',
              },
              {
                key: '10',
                icon: <SettingOutlined />,
                label: 'General Setting',
              },
            ]}
          />
        </Sider>
        <Layout className="p-6 min-h-[calc(100vh-75px)]">
          <Breadcrumb className="mx-0 text-base ">
            {/* <Breadcrumb.Item>Agent Listing</Breadcrumb.Item> */}
            {/* <Breadcrumb.Item>John Brown</Breadcrumb.Item> */}
          </Breadcrumb>
          <Content className="p-6 m-0 bg-white">
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
    // <div>{children}</div>
  );
};
export default MainLayout;
