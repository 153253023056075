import { get, patch } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useFetchTravellerList = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (traveller: any) => {
        return await get("traveller/list?travellerId=" + traveller.travellerId + "&statusFilter=" + traveller.statusFilter + "&searchFilter=" + traveller.searchFilter + "&pageNumber=" + traveller.pageNumber + "&pageSize=" + traveller.pageSize);
    }, options);
};

export const useFetchTravellerStatuses = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("traveller/statuses");
    }, options);
};

export const useUpdateUserStatus = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("traveller/status/update?travellerId=" + data.travellerId + "&statusCode=" + data.statusCode + "&reason=" + data.reason, data);
    }, options);
};

export const useGetTravellerTripPaymentHistory = (options?: UseMutationOptions<any, any, { travellerId: string, tripId?: string }, any>) => {
    return useMutation(
        async ({ travellerId, tripId }: { travellerId: string, tripId?: string }) => {
            return await get("traveller/payment-history?travellerId=" + travellerId + (tripId ? "&tripId=" + tripId : ""));
        },
        options);
};

export const useFetchTravellerProfileInfo = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (travellerId: string) => {
        return await get(`traveller/profile-info?travellerId=${travellerId}`);
    }, options);
};