import { post, get, patch } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useFetchUserList = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("user/list?pageNumber=" + data.pageNumber + "&pageSize=" + data.pageSize + "&statusFilter=" + data.statusFilter);
    }, options);
};

export const useSendVerificationCode = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("user/send-code", data, false);
    }, options);
};

export const useVerifyUserVerificationCode = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (verifiedData: any) => {
        return await patch("user/verify-code", verifiedData, false);
    }, options);
};

export const useSetPassword = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("user/change-password", data);
    }, options);
};

export const useFetchUser = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("user/me");
    }, options);
};

export const useRegister = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("user/register", data, false);
    }, options);
};

export const useCompleteUserProfile = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("traveller/complete-profile", data);
    }, options);
};

export const useFetchUserByPhoneNo = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("user/phone-number?phoneNumber=" + data.phoneNumber);
    }, options);
};

export const useUpdateVerifiedStatus = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("user/update-verified?userId=" + data.userId + "&codeType=" + data.codeType, {});
    }, options);
};

export const useGetAgentPayoutPreferences = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("agent/payout-preferences");
    }, options);
};