import { storage } from "../utils/storage";

export const isNotNullUndefinedBlank = (data: any) => {
    return (data !== null && data !== undefined && data.toString().trim() !== "");
};

export const isNotNullUndefined = (data: any) => {
    return (data !== null && data !== undefined);
};

export const isValidJSON = (jsonString: string): boolean => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (error) {
        console.warn(error);
        return false;
    }
};

export const formatDateTime = (value: number | Date | string, format: string) => {
    // let date = new Date(value);
    let date: Date;

    if (typeof value === 'string') {
        if (value.includes('-') || value.includes('/')) {
            date = new Date(value);
        } else if (value.includes(':')) {
            const currentDate = new Date();
            const [hours, minutes] = value.split(':');
            date = new Date(currentDate.setHours(Number(hours), Number(minutes)));
        } else {
            return '';
        }
    } else {
        date = new Date(value);
    }

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    // Define format patterns
    const patterns = {
        "DD": () => String(date.getDate()).padStart(2, '0'),                   // Day of the month (01-31)
        "MM": () => (date.getMonth() + 1).toString().padStart(2, '0'),         // Month abbreviation (Jan-Dec)
        "MMM": () => monthNames[date.getMonth()],                              // Month abbreviation (Jan-Dec)
        "YYYY": () => String(date.getFullYear()),                              // Full year (e.g., 2023)
        "hh": () => String(date.getHours()).padStart(2, '0'),                  // Hours (00-23)
        "HH": () => String(date.getHours() % 12 || 12).padStart(2, '0'),       // Hours (01-12)
        "mm": () => String(date.getMinutes()).padStart(2, '0'),                // Minutes (00-59)
        "AMPM": () => date.getHours() >= 12 ? 'PM' : 'AM',                     // AM/PM
    };

    const sortedPatterns = Object.entries(patterns).sort(([a], [b]) => b.length - a.length);

    // Replace format patterns with corresponding values
    let formattedDate = format;
    for (const [pattern, replacer] of sortedPatterns) {
        formattedDate = formattedDate.replace(pattern, replacer());
    }
    return formattedDate;
};

export function getDateOrdinalSuffix(day: number) {
    if (day > 3 && day < 21) return 'th'; // catch 11th, 12th, 13th
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
};

export const formatDateWithOrdinalSuffix = (date: any, isYearRequired: boolean = false) => {
    const day = date.getDate();
    const suffix = getDateOrdinalSuffix(day);
    const options = { month: 'long' };
    const month = date.toLocaleDateString('en-US', options);
    const year = date.getFullYear();
    const formattedDate = isYearRequired ? `${day}${suffix} ${month} ${year}` : `${day}${suffix} ${month}`;
    return formattedDate;
}

export const statusWiseRedirectToPage = () => {
    const userRoleId = storage.getUserRoleId() ?? "";
    const userStatus = storage.getUserStatusId() ?? "";
    const loginType = storage.getLoginType() ?? "";
    const userId = storage.getUserId();
    let redirectAt = "/";
    if (userRoleId !== "" && userStatus !== "" && userId !== "") {
        if (userRoleId === '2' && userStatus === '8' && isNotNullUndefinedBlank(userId)) {
            redirectAt = "/agentdocument?id=" + userId;
        }
        else if (userRoleId === '1' && userStatus === '1') {
            redirectAt = "/attendants";
        } else if (userRoleId === '2' || userRoleId === '3') {
            if (userStatus === '9' || (userStatus === '10' && loginType === "google")) {
                redirectAt = "/completeprofile";
            }
            else if (userStatus === '10' && loginType !== "google") {
                redirectAt = "/authorization";
            }
            else {
                redirectAt = '/complete';
            }
        }
    }
    return redirectAt;
}

const getRandomCharacter = (characters: any) => {
    const randomType = characters[Math.floor(Math.random() * characters.length)];
    const randomIndex = Math.floor(Math.random() * randomType.length);
    return randomType[randomIndex];
};

export const onAutoGeneratePassword = () => {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()-_=+[]{};:<>?';
    const allCharacters = [lowercase, uppercase, numbers, specialChars];
    const password =
        getRandomCharacter(lowercase) +
        getRandomCharacter(uppercase) +
        getRandomCharacter(numbers) +
        getRandomCharacter(specialChars) +
        Array.from({ length: 4 }, () => getRandomCharacter(allCharacters)).join('');

    return password;
};

export const compareObjects = (object1: any, object2: any): boolean => {
    const keys1 = Object.keys(object1).sort();
    const keys2 = Object.keys(object2).sort();
    if (keys1.length !== keys2.length) {
        // console.warn('Different number of keys');
        return false;
    }

    for (const key of keys1) {
        const value1 = object1[key];
        const value2 = object2[key];

        if (Array.isArray(value1) && Array.isArray(value2)) {
            if (value1.length !== value2.length) {
                // console.warn(`Array length mismatch for key ${key}`);
                return false;
            }
            for (let i = 0; i < value1.length; i++) {
                if (!compareObjects(value1[i], value2[i])) {
                    // console.warn(`Array element mismatch at index ${i} for key ${key}`);
                    return false;
                }
            }
        } else if (value1 instanceof Date && value2 instanceof Date) {
            if (value1.getTime() !== value2.getTime()) {
                // console.warn(`Date mismatch for key ${key}`);
                return false;
            }
        } else if (typeof value1 === 'object' && value1 !== null && typeof value2 === 'object' && value2 !== null) {
            if (!compareObjects(value1, value2)) {
                // console.warn(`Object mismatch for key ${key}`);
                return false;
            }
        } else if (value1 !== value2) {
            // console.warn(`Primitive mismatch for key ${key}: ${value1} !== ${value2}`);
            return false;
        }
    }
    return true;
};

export const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
};

export const isValidPhoneNumber = (number: any) => {
    return /^\d{7,12}$/.test(number);
};

export const getLabel = (label: string, required: boolean) => {
    return required
        ? `${label} *`
        : `${label} (optional)`;
};
