import React, { useEffect, useRef, useState } from "react";
import { Space, Button, Input, Drawer, Form, Row, Col, Upload, Modal, Table, message, UploadFile } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useGetMostVisitedPlaces, useAddMostVisitedPlaces, useDeleteMostVisitedPlace } from '../services/mostvisitedplaces.service';
import IResponse from "../types/response";
import { IMostVisitedPlaces } from "../types/mostvisitedplaces";
import { compareObjects, getLabel, isNotNullUndefined, isNotNullUndefinedBlank } from "../helpers/common";
import { DeleteObjectCommand, DeleteObjectCommandInput, S3, ObjectCannedACL } from '@aws-sdk/client-s3';
import { Upload as S3Upload } from '@aws-sdk/lib-storage';
import { awsS3config } from '../helpers/config';
import { storage } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import { eventEmitter } from "../helpers/eventemitter";

interface DataType {
    key: React.Key;
    PlaceName: string;
    Description: string;
    FeaturedImage: string;
    ImageUpload: string[];
};

const Mostvisitedplace = () => {
    const { Search } = Input;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [featuredImage, setFeaturedImage] = useState<UploadFile[]>([]);
    const [galleryImages, setGalleryImages] = useState<UploadFile[]>([]);
    const [form] = Form.useForm();
    const [mostVisitedPlaces, setMostVisitedPlaces] = useState<IMostVisitedPlaces[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [visibleImagesIndex, setVisibleImagesIndex] = useState(0);
    const [placeDescription, setPlaceDescription] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const editor_ref = useRef<ReactQuill>(null);
    const [modalType, setModalType] = useState<'delete' | 'update' | 'add' | ''>('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [mostVisitedPlaceId, setMostVisitedPlaceId] = useState('');
    const [formUpdateValues, setFormUpdateValues] = useState<any>({});
    const formInitialValues = useRef<any>({});
    const formUpdatedValues = useRef<any>({});
    const searchValue = useRef<string>("");

    useEffect(() => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        if (userRoleId !== 1) {
            navigate("/");
        }
        else {
            getMostVisitedPlacesList("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (open && editor_ref.current) {
            const editor = editor_ref.current.getEditor();
            editor.setContents(editor.clipboard.convert(placeDescription));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const getMostVisitedPlacesList = async (searchValue: string) => {
        getMostVisitedPlacesMutation.mutate({ searchFilter: searchValue });
    };

    const getMostVisitedPlacesMutation = useGetMostVisitedPlaces({
        onSuccess: (response: IResponse) => {
            if (isNotNullUndefined(response) && response.data) {
                setIsLoading(false);
                setMostVisitedPlaces(response.data);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const data = mostVisitedPlaces.map((item) => ({
        key: item.placeId,
        PlaceName: item.placeName,
        Description: item.description,
        FeaturedImage: item.featuredImageUrl,
        ImageUpload: isNotNullUndefinedBlank(item.galleryImageUrl) ? JSON.parse(item.galleryImageUrl).map((x: { ImageUrl: any; }) => x.ImageUrl) : []
    }));

    const modules = {
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'header': '1' }, { 'header': '2' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'align': [] }],
            ['blockquote', 'code-block'],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'color', 'background',
        'script', 'align', 'direction'
    ];

    const openDrawer = () => {
        setIsEdit(false);
        form.resetFields();
        setPlaceDescription("");
        setFeaturedImage([]);
        setGalleryImages([]);
        setOpen(true);
        eventEmitter.emit("clickableChange", true);
    };

    const closeDrawer = () => {
        form.resetFields();
        setPlaceDescription("");
        setFeaturedImage([]);
        setGalleryImages([]);
        setOpen(false);
        eventEmitter.emit("clickableChange", false);
    };

    const handleImageClick = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedImage(null);
    };

    const addMostvisitedPlaces = useAddMostVisitedPlaces({
        onSuccess: (response: IResponse) => {
            if (response !== null && response.statusCode === 200) {
                getMostVisitedPlacesList(searchValue.current ?? "");
                message.success(response.statusMessage);
                closeDrawer();
            }
            else {
                setIsLoading(false);
                message.warning(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        },
    });

    const onDeleteMostVisitedPlace = (record: any) => {
        setModalType('delete');
        setIsConfirmModalVisible(true);
        setFormUpdateValues({ placeName: record.PlaceName });
        setMostVisitedPlaceId(record.key.toString());
    }

    const deleteMostVisitedPlace = useDeleteMostVisitedPlace({
        onSuccess: (response: IResponse) => {
            if (response !== null && response.statusCode === 200) {
                message.success(response.statusMessage);
                getMostVisitedPlacesList("");
            }
            else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        },
    });

    const handleSave = async () => {
        try {
            if (modalType === 'delete') {
                deleteMostVisitedPlace.mutate(mostVisitedPlaceId);
            }
            else if (modalType === 'update' || modalType === 'add') {
                setIsLoading(true);
                addMostvisitedPlaces.mutate({
                    placeId: formUpdateValues.placeId,
                    placeName: formUpdateValues.placeName,
                    description: formUpdateValues.description,
                    featuredImageUrl: formUpdateValues.featuredImageUrl,
                    galleryImageUrl: formUpdateValues.galleryImageUrl
                });
            }
            setIsConfirmModalVisible(false);
        } catch (error) {
            message.error('Failed to save');
        }
    };

    const onEdit = (record: any) => {
        form.setFieldsValue({
            placeid: record.key,
            placename: record.PlaceName,
            description: record.Description
        });
        setPlaceDescription(record.Description);


        if (isNotNullUndefinedBlank(record.FeaturedImage)) {
            const featuredImageFile: UploadFile = {
                uid: '1',
                name: 'Featured Image',
                status: 'done',
                url: record.FeaturedImage,
            };
            setFeaturedImage([featuredImageFile]);
        }
        else {
            setFeaturedImage([]);
        }

        const galleryImageFiles: UploadFile[] = Array.isArray(record.ImageUpload)
            ? record.ImageUpload.map((url: string, index: number) => ({
                uid: `${index}`,
                name: `Gallery Image ${index + 1}`,
                status: 'done',
                url: url,
            }))
            : [];
        setGalleryImages(galleryImageFiles);
        formInitialValues.current = { placename: record.PlaceName, description: placeDescription, featuredImageUrl: featuredImage.length > 0 ? featuredImage[0].url : "", galleryImageUrl: galleryImages.length > 0 ? galleryImages.map(x => x.url) : [] }
        eventEmitter.emit("clickableChange", true);
        setIsEdit(true);
        setOpen(true);
    };

    const onSubmit = async () => {
        setModalType((isEdit ? 'update' : 'add'));
        const placeName = form.getFieldValue("placename");
        if (placeName && placeDescription) {
            const param = {
                placeId: form.getFieldValue("placeid"),
                placeName: placeName,
                description: placeDescription,
                featuredImageUrl: featuredImage.length > 0 ? featuredImage[0].url : "",
                galleryImageUrl: galleryImages.length > 0 ? galleryImages.map(x => x.url) : []
            };
            if (modalType === 'update') {
                formUpdatedValues.current = { placeName: placeName, description: placeDescription, featuredImageUrl: featuredImage.length > 0 ? featuredImage[0].url : "", galleryImageUrl: galleryImages.length > 0 ? galleryImages.map(x => x.url) : [] }
                if (compareObjects(formInitialValues.current, formUpdatedValues.current)) {
                    setIsLoading(false);
                    setOpen(false);
                    return;
                }
            }
            setFormUpdateValues(param);
            setIsConfirmModalVisible(true);
        } else {
            setIsLoading(false);
            message.error("Please fill in all mandatory fields before adding a place.")
        }

    };

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'delete') {
            message = "Confirm Deletion!";
        } else if (modalType === 'update') {
            message = "Confirm Update!";
        }
        else if (modalType === 'add') {
            message = "Confirm Add!";
        }
        return message;
    }

    const handleCancel = () => {
        setIsLoading(false);
        setIsConfirmModalVisible(false);
    };

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === 'delete') {
            modalContent = (
                <p>Are you sure you want to delete <b>{formUpdateValues.placeName}</b> Most Visited Place's Delete request? Once deleted, the most visited place will not be recovered. Do you wish to proceed?</p>
            );
        } else if (modalType === 'update') {
            modalContent = (
                <p>Are you sure you want to update <b>{formUpdateValues.placeName}</b> Most Visited Place's Update request? Once updated, the most visited places can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        else if (modalType === 'add') {
            modalContent = (
                <p>Are you sure you want to add <b>{formUpdateValues.placeName}</b> Most Visited Place's Add request? Once added, the most visited places can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        return modalContent;
    }

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as File);
        }
        setPreviewImage(file.url || (file.preview as string));
        setPreviewVisible(true);
    };

    const handlePreviewCancel = () => setPreviewVisible(false);

    const handleSingleChange = ({ fileList }: { fileList: UploadFile[]; }) => {
        if (fileList.length > 0) {
            fileList.forEach(async x => {
                if (x.status === 'uploading' && x.originFileObj && !x.url) {
                    const fileName = "most-visited_" + new Date().getTime() + "." + x.originFileObj.name.split(".")[1];
                    try {

                        const bucket = new S3({
                            credentials: {
                                accessKeyId: awsS3config.accessKeyId,
                                secretAccessKey: awsS3config.secretAccessKey,
                            },
                            region: awsS3config.region,
                        });

                        const params = {
                            Bucket: awsS3config.bucketName,
                            Key: "temp/" + fileName,
                            Body: x.originFileObj,
                            ContentType: x.originFileObj.type,
                            ACL: ObjectCannedACL.public_read_write
                        };

                        const upload = new S3Upload({
                            client: bucket,
                            params: params,
                        });
                        upload.done().then((data) => {
                            const fileUrl = (data["Location"] !== '' && data["Location"] !== null && data["Location"] !== undefined) ? data["Location"] : "";
                            x.url = fileUrl;
                            const featuredImageFile: UploadFile = {
                                uid: '1',
                                name: 'Featured Image',
                                status: 'done',
                                url: fileUrl,
                            };

                            setFeaturedImage([featuredImageFile]);
                        },
                            (err) => {
                                let errorJson = JSON.stringify(err);
                                let errorObject = JSON.parse(errorJson);
                                let msg = (errorObject["message"] !== '' && errorObject["message"] !== null && errorObject["message"] !== undefined) ? errorObject["message"] :
                                    'Something went wrong. Please try again!';
                                message.error(msg);
                                return false;
                            });
                    } catch (error) {
                        message.error("Error uploading file.");
                        return false;
                    }
                }
            });
        }
    };

    const onDeleteFeatureImageFile = async (file: UploadFile<any>) => {
        if (file && isNotNullUndefinedBlank(file.url)) {
            const fileName = file.url?.split('/').pop();
            if (isNotNullUndefinedBlank(fileName)) {
                try {
                    const s3Client = new S3({
                        credentials: {
                            accessKeyId: awsS3config.accessKeyId,
                            secretAccessKey: awsS3config.secretAccessKey,
                        },
                        region: awsS3config.region,
                    });

                    const params: DeleteObjectCommandInput = {
                        Bucket: awsS3config.bucketName,
                        Key: file.url?.includes("/temp/") ? "temp/" + fileName : "MostVisitedPlace/" + fileName
                    };

                    const command = new DeleteObjectCommand(params);
                    await s3Client.send(command);
                }
                catch (error) {
                    message.error("Error uploading file.");
                    return false;
                }
                const images = featuredImage.filter(x => x.url !== file.url);
                setFeaturedImage(images);
            }
        }
    };

    const handleMultipleChange = ({ fileList }: { fileList: UploadFile[]; }) => {
        if (fileList.length > 0 && galleryImages.length !== fileList.length) {
            fileList.forEach(async x => {
                if (x.status === 'uploading' && x.originFileObj && !x.url) {
                    const fileName = "most-visited_" + new Date().getTime() + "." + x.originFileObj.name.split(".")[1];
                    try {
                        const bucket = new S3({
                            credentials: {
                                accessKeyId: awsS3config.accessKeyId,
                                secretAccessKey: awsS3config.secretAccessKey,
                            },
                            region: awsS3config.region,
                        });

                        const params = {
                            Bucket: awsS3config.bucketName,
                            Key: "temp/" + fileName,
                            Body: x.originFileObj,
                            ContentType: x.originFileObj.type,
                            ACL: ObjectCannedACL.public_read_write
                        };

                        const upload = new S3Upload({
                            client: bucket,
                            params: params,
                        });
                        upload.done().then((data) => {
                            const fileUrl = (data["Location"] !== '' && data["Location"] !== null && data["Location"] !== undefined) ? data["Location"] : "";
                            x.url = fileUrl;

                            let imagesList: string[] = [];
                            fileList.forEach(y => {
                                imagesList.push(y.url ?? "");
                            });

                            const galleryImageFiles: UploadFile[] = Array.isArray(imagesList)
                                ? imagesList.map((url: string, index: number) => ({
                                    uid: `${index}`,
                                    name: `Gallery Image ${index + 1}`,
                                    status: 'done',
                                    url: url,
                                }))
                                : [];
                            setGalleryImages(galleryImageFiles);
                            return false;
                        },
                            (err) => {
                                let errorJson = JSON.stringify(err);
                                let errorObject = JSON.parse(errorJson);
                                let msg = (errorObject["message"] !== '' && errorObject["message"] !== null && errorObject["message"] !== undefined) ? errorObject["message"] :
                                    'Something went wrong. Please try again!';
                                message.error(msg);
                                return false;
                            });
                    } catch (error) {
                        message.error("Error uploading file.");
                        return false;
                    }
                }
            });
        }
    };

    const onDeleteGalleryImagesFile = async (file: UploadFile<any>) => {
        if (file && isNotNullUndefinedBlank(file.url)) {
            const fileName = file.url?.split('/').pop();
            if (isNotNullUndefinedBlank(fileName)) {
                try {
                    const s3Client = new S3({
                        credentials: {
                            accessKeyId: awsS3config.accessKeyId,
                            secretAccessKey: awsS3config.secretAccessKey,
                        },
                        region: awsS3config.region,
                    });

                    const params: DeleteObjectCommandInput = {
                        Bucket: awsS3config.bucketName,
                        Key: file.url?.includes("/temp/") ? "temp/" + fileName : "MostVisitedPlace/" + fileName
                    };

                    const command = new DeleteObjectCommand(params);
                    await s3Client.send(command);
                }
                catch (error) {
                    message.error("Error uploading file.");
                    return false;
                }
                const images = galleryImages.filter(x => x.url !== file.url);
                setGalleryImages(images);
            }
        }
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    function getBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };

    const onSearch = (value: any) => {
        getMostVisitedPlacesList(value);
        searchValue.current = value;
    };

    return (
        <div>
            <div className="flex justify-between align-middle pb-5">
                <h2 className="text-2xl font-semibold text-black">Most Visited Places</h2>
                <div className="flex justify-between gap-5">
                    <div className="w-80 max-w-80">
                        <Search
                            placeholder="Search most visited place"
                            size="large"
                            enterButton
                            className="search-field"
                            onSearch={onSearch}
                        />
                    </div>
                    <Button className="h-10 bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out" onClick={openDrawer} icon={<PlusOutlined />}>
                        New place
                    </Button>
                </div>
            </div>

            <div style={{ overflowX: 'auto' }}>
                <Table
                    className="interests-table"
                    dataSource={data}
                    bordered
                    pagination={{ pageSize: 10 }}
                    scroll={{ x: 'max-content' }}
                >
                    <Table.Column
                        title="Place Name"
                        dataIndex="PlaceName"
                        key="PlaceName"
                        align="center"
                        width={150}
                    />
                    <Table.Column
                        title="Description"
                        dataIndex="Description"
                        key="Description"
                        align="center"
                        width={300}
                        render={(description: string) => (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: description.length > 160 ? `${description.substring(0, 100)}...` : description
                                }} />
                        )}
                    />
                    <Table.Column
                        title="Featured Image"
                        dataIndex="FeaturedImage"
                        key="featuredImageUrl"
                        align="center"
                        width={150}
                        render={(imageUrl: string) => (
                            imageUrl !== null && imageUrl !== undefined && imageUrl !== "" ?
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        src={imageUrl}
                                        alt="Featured"
                                        style={{ width: 30, height: 30, objectFit: 'cover', cursor: 'pointer' }}
                                        onClick={() => handleImageClick(imageUrl)}
                                    />
                                </div> : <div></div>
                        )}
                    />
                    <Table.Column
                        title="Gallery Images"
                        dataIndex="ImageUpload"
                        key="galleryImageUrl"
                        align="center"
                        width={150}
                        render={(images: string[]) => {
                            const startIndex = visibleImagesIndex * 3;
                            const visibleImages = images.slice(startIndex, startIndex + 3);

                            return (
                                <Space>
                                    {visibleImages.map((image: string, index: number) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`uploaded-${index}`}
                                            style={{ width: 30, height: 30, objectFit: 'cover', cursor: 'pointer' }}
                                            onClick={() => handleImageClick(image)}
                                        />
                                    ))}
                                    {images.length > (startIndex + 3) && (
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setVisibleImagesIndex(visibleImagesIndex + 1)}
                                        >
                                            +{Math.min(3, images.length - (startIndex + 3))}
                                        </span>
                                    )}
                                    {visibleImagesIndex > 0 && (
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setVisibleImagesIndex(visibleImagesIndex - 1)}>
                                            Previous
                                        </span>
                                    )}
                                </Space>
                            );
                        }}
                    />
                    <Table.Column
                        title="Action"
                        key="action"
                        align="center"
                        width={150}
                        render={(_: any, record: DataType) => (
                            <Space size="middle">
                                <Button icon={<EditOutlined />} onClick={() => onEdit(record)}></Button>
                                <Button icon={<DeleteOutlined />} onClick={() => onDeleteMostVisitedPlace(record)}></Button>
                            </Space>
                        )}
                    />
                </Table>
            </div>
            <Modal open={isModalVisible} footer={null} onCancel={handleModalClose} className="image-gallery">
                {selectedImage && <img alt="Featured" style={{ width: '100%' }} src={selectedImage} />}
            </Modal>
            <Drawer
                title={isEdit ? "Edit Most Visited Places" : "Add Most Visited Places"}
                width={520}
                onClose={closeDrawer}
                open={open}
                mask={false}
                bodyStyle={{ paddingBottom: 80 }}>
                <Form layout="vertical" form={form} onSubmitCapture={onSubmit}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="placeid"
                                label="Place ID"
                                style={{ display: 'none' }}>
                                <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="placename"
                                label="Place Name"
                                rules={[{ required: true, message: 'Please enter place name' }]}
                            >
                                <Input size="large" placeholder="Please enter place name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Description"
                                rules={[{ required: true, message: 'Please enter URL description' }]}
                            >
                                <ReactQuill
                                    theme="snow"
                                    value={placeDescription}
                                    onChange={value => setPlaceDescription(value)}
                                    placeholder="Please enter URL description"
                                    modules={modules}
                                    formats={formats}
                                    className="custom-quill"
                                    ref={editor_ref}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="FeaturedImage" label={getLabel("Upload Featured Image", false)}>
                                <Upload
                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    fileList={featuredImage}
                                    onPreview={handlePreview}
                                    onChange={handleSingleChange}
                                    onRemove={e => onDeleteFeatureImageFile(e)}
                                >
                                    {featuredImage.length >= 1 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="galleryImageUrl" label={getLabel("Upload Gallery Images", false)}>
                                <Upload
                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    fileList={galleryImages}
                                    onPreview={handlePreview}
                                    onChange={handleMultipleChange}
                                    onRemove={e => onDeleteGalleryImagesFile(e)}
                                >
                                    {galleryImages.length >= 8 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Modal open={previewVisible} footer={null} onCancel={handlePreviewCancel}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                    <Space className="w-full justify-end">
                        <Button onClick={closeDrawer} className="hover:!text-black hover:!border-black">Cancel</Button>
                        <Button htmlType="submit" loading={isLoading} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">{isEdit ? "Update" : "Add"}</Button>
                    </Space>
                </Form>
            </Drawer>

            <Modal
                title={<span className="custom-modal-title">{getModalTitle()}</span>}
                open={isConfirmModalVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                footer={[
                    <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                        Cancel
                    </Button>,
                    <Button key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                        Confirm
                    </Button>,
                ]}
            >
                {getModelContent()}
            </Modal>
        </div>
    );
}

export default Mostvisitedplace;