import { useEffect, useRef, useState } from 'react';
import { Row, Col, message } from 'antd';
import Images from '../assets/image/index';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSendVerificationCode } from '../services/user.service';
import { storage } from '../utils/storage';


const Forgotpassword = () => {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const email = useRef('');
    const codeType = useRef('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        email.current = (storage.getEmailId() ?? "");
        codeType.current = "email";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendVerificationCode = async (event: any) => {
        (document.getElementById('ddlEmail') as any).value = email.current;
        event.preventDefault();
        setIsLoading(true);
        storage.setPreviousUrlPath(window.location.pathname);
        sendVerificationCodeMutation.mutate({ userEmail: email.current });
    };

    const sendVerificationCodeMutation = useSendVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                setIsLoading(false);
                storage.setEmailId(email.current);
                storage.setCodeType(codeType.current);
                message.success("OTP has been sent successfully.");
                navigate('/authorization');
            }
            else {
                setIsLoading(false);
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        }
    });

    return (
        <div>
            <div className="app-container">
                <Row>
                    <Col span={12} className="hidden lg:block bg-black h-screen">
                        <div className='h-screen bg-login-background bg-no-repeat bg-cover flex justify-center items-center'>
                            <div>
                                <div className='flex justify-center w-full'>
                                    <div className='w-72'>
                                        <img src={Images.triplogo} className='w-full' alt="triplogo" />
                                    </div>
                                </div>
                                <div className='mt-20 flex justify-center'>
                                    <img src={Images.travellogo} className='w-[335px] 2xl:w-[443px]' alt="travel-logo" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={24} lg={12}>
                        <div className='block  lg:w-0 lg:h-0 lg:hidden'>
                            <div className='flex justify-center py-16'>
                                <img src={Images.mobilelogo} className='w-[140px] h-[60px] lg:w-[219px] lg:h-[96px]' alt="triplogo" />
                            </div>
                        </div>
                        <div className="flex justify-center items-center lg:h-screen">
                            <div className="p-6 w-full max-w-md">
                                <p className="text-center text-[32px] font-extrabold mb-6 text-black">Forgot your password?</p>
                                <Form
                                    layout="vertical"
                                    form={form}
                                    className="space-y-4"
                                    onSubmitCapture={sendVerificationCode}
                                >
                                    <Form.Item className='font-semibold' label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ]}>
                                        <Input className="font-medium text-neutral-600 h-12" placeholder="Enter your email"
                                            id='ddlEmail'
                                            defaultValue={(storage.getEmailId() ?? "")}
                                            onChange={(e) => { email.current = (e.target.value) }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" className="h-14 w-full text-xl bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black" loading={isLoading}>Reset password</Button>
                                    </Form.Item>
                                </Form>
                                <div className="mt-6 sm:!mt-10">
                                    <p className="text-base font-normal text-center text-black-russian">Don't have an account?<span className="text-base font-semibold">
                                        <a href="/" className="outline-none cursor-pointer ml-1" onClick={() => { storage.clearPreviousUrlPath() }}>
                                            <span className="text-xs sm:text-base text-black font-semibold underline outline-none">Sign in!</span>
                                        </a>
                                    </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Forgotpassword;