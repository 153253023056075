import { useEffect, useState } from 'react';
import { Row, Col, message, Select, Upload } from 'antd';
import Images from '../assets/image/index';
import { Button, Form } from 'antd';
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { isNotNullUndefined, isNotNullUndefinedBlank, statusWiseRedirectToPage } from '../helpers/common';
import { DeleteObjectCommand, DeleteObjectCommandInput, S3, ObjectCannedACL } from '@aws-sdk/client-s3';
import { Upload as S3Upload } from '@aws-sdk/lib-storage';
import { awsS3config } from '../helpers/config';
import { useAddAgentDocument, useGetDocumentTypes, useUpdateAgentStatus } from '../services/agent.service';
import IResponse from '../types/response';
import { IDocumentTypes } from '../types/documenttypes';
import { useNavigate } from 'react-router-dom';
import { useFetchUser } from '../services/user.service';
import { storage } from '../utils/storage';

const AgentDocument = () => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const navigate = useNavigate();
    const [documentTypes, setDocumentTypes] = useState<IDocumentTypes[]>([]);
    const [fileType, setFileType] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [documentUrl, setDocumentUrl] = useState<string | null>(null);

    useEffect(() => {
        let token = storage.getToken() ?? "";
        if (!isNotNullUndefinedBlank(token)) {
            navigate("/");
            return;
        }
        const urlSearchParams = new URLSearchParams(window.location.search);
        form.setFieldValue("agentId", urlSearchParams.get('id'));
        fetchDocumentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchDocumentTypes = async () => {
        fetchDocumentTypesMutation.mutate({});
    };

    const logout = async () => {
        window.localStorage.clear();
        navigate('/');
    };

    const fetchDocumentTypesMutation = useGetDocumentTypes({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                setDocumentTypes(response.data);
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const handleUpload = (file: any) => {
        if (file) {
            if (isNotNullUndefinedBlank(documentUrl)) {
                onDeleteFeatureImageFile();
            }
            const fileName = "agent_document" + new Date().getTime() + "." + file.name.split(".")[1];
            try {

                const bucket = new S3({
                    credentials: {
                        accessKeyId: awsS3config.accessKeyId,
                        secretAccessKey: awsS3config.secretAccessKey,
                    },
                    region: awsS3config.region,
                });

                const params = {
                    Bucket: awsS3config.bucketName,
                    Key: "temp/" + fileName,
                    Body: file,
                    ContentType: file.type,
                    ACL: ObjectCannedACL.public_read_write
                };

                const upload = new S3Upload({
                    client: bucket,
                    params: params,
                });
                upload.done().then((data) => {
                    const fileUrl = (data["Location"] !== '' && data["Location"] !== null && data["Location"] !== undefined) ? data["Location"] : "";
                    file.url = fileUrl;
                    setDocumentUrl(fileUrl);
                    setFileType(file.type);
                },
                    (err) => {
                        let errorJson = JSON.stringify(err);
                        let errorObject = JSON.parse(errorJson);
                        let msg = (errorObject["message"] !== '' && errorObject["message"] !== null && errorObject["message"] !== undefined) ? errorObject["message"] :
                            'Something went wrong. Please try again!';
                        message.error(msg);
                        return false;
                    });
            } catch (error) {
                message.error("Error uploading file.");
                return false;
            }
        }
        return false;
    };

    const onDeleteFeatureImageFile = async () => {
        if (isNotNullUndefinedBlank(documentUrl)) {
            const fileName = documentUrl?.split('/').pop();
            if (isNotNullUndefinedBlank(fileName)) {
                try {
                    const s3Client = new S3({
                        credentials: {
                            accessKeyId: awsS3config.accessKeyId,
                            secretAccessKey: awsS3config.secretAccessKey,
                        },
                        region: awsS3config.region,
                    });

                    const params: DeleteObjectCommandInput = {
                        Bucket: awsS3config.bucketName,
                        Key: documentUrl?.includes("/temp/") ? "temp/" + fileName : "AgentDocument/" + fileName
                    };

                    const command = new DeleteObjectCommand(params);
                    await s3Client.send(command);
                }
                catch (error) {
                    message.error("Error uploading file.");
                    return false;
                }
                setDocumentUrl("");
            }
        }
    };

    const handleRemove = () => {
        setDocumentUrl(null);
        setFileType(null);
        message.success('Document has been removed successfully.');
    };

    const renderPreview = () => {
        if (fileType?.includes('image')) {
            return (
                <div className="mt-6 h-32 flex">
                    <a href={documentUrl || undefined} target="_blank" rel="noopener noreferrer">
                        <img src={documentUrl || undefined} alt="Uploaded" className="h-full object-contain" />
                    </a>
                    <Button onClick={handleRemove} icon={<DeleteOutlined />} className="ml-5 !px-[15px]">
                    </Button>
                </div>
            );
        } else if (fileType === 'application/pdf') {
            return (
                <div className="mt-6 items-center flex">
                    <a href={documentUrl ?? undefined} target='_blank' rel="noreferrer">
                        <span>Uploaded PDF</span>
                    </a>
                    <Button onClick={handleRemove} icon={<DeleteOutlined />} className="ml-5">
                    </Button>
                </div>
            );
        }
        return null;
    };

    const onSubmit = (values: { agentId: string, documentType: number }) => {
        if (!isNotNullUndefinedBlank(values.documentType)) {
            message.error("Please choose a document type from the dropdown.")
        }
        else if (!isNotNullUndefinedBlank(documentUrl)) {
            message.error("Please upload the document.")
        }
        else {
            setLoading(true);
            addAgentDocument.mutate({
                agentId: values.agentId,
                documentTypeId: values.documentType,
                documentUrl: documentUrl
            });
        }
    };

    const addAgentDocument = useAddAgentDocument({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                message.success(response.statusMessage);
                fetchUser();
                setLoading(false);
            } else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const fetchUser = () => {
        if (isNotNullUndefinedBlank(storage.getToken() ?? "")) {
            fetchUserMutation.mutate({});
        }
    }

    // const onSkipDocumentUpload = () => {
    //     updateAgentStatus.mutate({ agentId: form.getFieldValue("agentId"), statusCode: "DOCUMENT_UPLOADED", reason: "" });
    // };

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                storage.setUserRoleId(response.data.userRoleId);
                storage.setUserStatusId(response.data.statusId);
                storage.setUserId(response.data.userId);
                navigate(statusWiseRedirectToPage());
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateAgentStatus = useUpdateAgentStatus({
        onSuccess: (response: any) => {
            setLoading(false);
            if (response.statusCode === 200) {
                fetchUser();
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    return (
        <div>
            <div className="app-container">
                <Row className="min-h-screen">
                    <Col span={12} className="hidden lg:block bg-black h-screen">
                        <div className='h-screen bg-login-background bg-no-repeat bg-cover flex justify-center items-center'>
                            <div>
                                <div className='flex justify-center w-full'>
                                    <div className='flex justify-center w-full'>
                                        <div className='w-72'>
                                            <img src={Images.triplogo} className='w-full' alt="triplogo" />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-20 flex justify-center'>
                                    <img src={Images.travellogo} className='w-[335px] 2xl:w-[443px]' alt="travel-logo" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={24} lg={12}>
                        <div className="block  lg:w-0 lg:h-0 lg:hidden">
                            <div className="flex justify-center py-16">
                                <img
                                    src={Images.mobilelogo}
                                    className="w-[140px] h-[60px] lg:w-[219px] lg:h-[96px]"
                                    alt="triplogo"
                                />
                            </div>
                        </div>
                        <div className='flex justify-center items-center lg:h-screen'>
                            <div className='p-6 w-full max-w-md'>
                                <h1 className='text-[32px] font-extrabold'>Identity Verification</h1>
                                <div className='mt-2 text-base'>
                                    <p>To complete your registration, please upload your government-approved identification documents. These documents are essential for verifying your identity and ensuring a secure and trustworthy platform.</p>
                                </div>
                                <div className='mt-4'>
                                    <p className='font-bold text-base'>Select a document to upload</p>
                                </div>
                                <div className='mt-2'>
                                    <Form layout="vertical" form={form} onFinish={onSubmit}>
                                        <Form.Item name="agentId" hidden></Form.Item>
                                        <Form.Item className='font-semibold' name="documentType">
                                            <Select placeholder="Select a document to upload" className="font-medium text-neutral-600 h-12" allowClear>
                                                {documentTypes ? documentTypes.map(x => (
                                                    <Option key={x.documentTypeId} value={x.documentTypeId}>
                                                        {x.documentTypeName}
                                                    </Option>
                                                )) : ""}
                                            </Select>
                                        </Form.Item>
                                        <div>
                                            <h1 className="text-base font-bold">Upload Your Documents</h1>
                                            <div className="mt-4">
                                                <Upload beforeUpload={handleUpload} showUploadList={false} accept="image/*,.pdf">
                                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                </Upload>
                                            </div>
                                            {documentUrl && renderPreview()}
                                        </div>
                                        <Form.Item>
                                            <Button
                                                htmlType="submit"
                                                loading={loading}
                                                className="h-14 w-full mt-6 text-xl bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                        <Form.Item className='text-center flex items-center justify-center pb-9'>
                                            <Button onClick={logout} type="link" className="outline-none flex justify-center items-center cursor-pointer text-black text-base font-semibold underline">Log out</Button>
                                        </Form.Item>
                                    </Form>
                                    {/* <div className="mt-6">
                                        <p className="text-base font-semibold text-center">
                                            <span className="outline-none cursor-pointer ml-1" onClick={onSkipDocumentUpload}>
                                                <span className="text-xs sm:text-base text-black font-semibold underline outline-none">
                                                    Skip
                                                </span>
                                            </span>
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default AgentDocument;