/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Input, message, Modal, Checkbox, Spin } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { useFetchCountry, useAddCountry, useDeleteCountry } from "../services/countries.service";
import { Col, Drawer, Form, Row } from 'antd';
import Column from "antd/es/table/Column";
import IResponse from '../types/response';
import { ICountry, IEditCountry } from "../types/countries";
import { useNavigate } from "react-router-dom";
import { compareObjects, isNotNullUndefined, isNotNullUndefinedBlank } from "../helpers/common";
import { storage } from "../utils/storage";
import { eventEmitter } from "../helpers/eventemitter";

const Countries: React.FC = () => {
    const { Search } = Input;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState<ICountry[]>([]);
    const [form] = Form.useForm();
    const [editingCountry, setEditingCountry] = useState<IEditCountry | null>(null);
    const isCountryEdited = useRef<boolean>(false);
    const [modalType, setModalType] = useState<'delete' | 'update' | 'add' | ''>('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [countryId, setCountriesId] = useState();
    const [formUpdateValues, setFormUpdateValues] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const formInitialValues = useRef<any>({});
    const formUpdatedValues = useRef<any>({});
    const searchValue = useRef<string>("");

    const checkIfCountryEdited = (country: IEditCountry | null): boolean => {
        if (!country) return false;
        return country.key !== 0 || country.CountryName !== '' || country.DialingCode !== '';
    };

    useEffect(() => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        if (userRoleId !== 1) {
            navigate("/");
        }
        else {
            getCountryList("", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        isCountryEdited.current = (checkIfCountryEdited(editingCountry));
    }, [editingCountry]);

    const getCountryList = async (searchValue: string, isServiceable: any) => {
        getCountryMutation.mutate({ searchFilter: searchValue, isServiceable: isServiceable });
    };

    const getCountryMutation = useFetchCountry({
        onSuccess: (response: IResponse) => {
            if (response != null && response !== undefined) {
                response.data = response;
                setCountry(response.data);
            } else {
                message.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const showDrawer = () => {
        eventEmitter.emit("clickableChange", true);
        setEditingCountry(null);
        form.resetFields();
        setOpen(true);

    };

    const onClose = () => {
        eventEmitter.emit("clickableChange", false);
        form.resetFields();
        setOpen(false);
    };

    const addCountry = useAddCountry({
        onSuccess: () => {
            getCountryList(searchValue.current ?? "", "");
            onClose();
            setIsLoading(false);
            form.resetFields();
        },
        onError: (error) => {
            setIsLoading(false);
            alert(error.message);
        },
    });

    const deleteCountry = useDeleteCountry({
        onSuccess: () => {
            message.success("Country has been deleted successfully!");
            getCountryList("", "");
        },
        onError: (error: any) => {
            if (isNotNullUndefined(error.response) && isNotNullUndefined(error.response.data) && isNotNullUndefinedBlank(error.response.data.message)) {
                message.error(error.response.data.message);
            } else {
                message.error("An unexpected error occurred.");
            }
        },
    });

    const onDeleteCountry = (record: any) => {
        setModalType('delete');
        setIsConfirmModalVisible(true);
        setFormUpdateValues({ countryName: record.CountryName });
        setCountriesId(record.key.toString());
    }

    const handleSave = async () => {
        try {
            if (modalType === 'delete') {
                deleteCountry.mutate(countryId);
            }
            else if (modalType === 'update' && editingCountry) {
                setIsLoading(true);
                addCountry.mutate({ ...editingCountry, ...formUpdateValues });
                message.success("Country has been updated successfully!");
            }
            else if (modalType === 'add') {
                setIsLoading(true);
                addCountry.mutate(formUpdateValues);
                message.success("Country has been added successfully!");
            }
            setIsConfirmModalVisible(false);
        } catch (error) {
            message.error('Failed to save');
        }
    };

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'delete') {
            message = "Confirm Deletion!";
        } else if (modalType === 'update') {
            message = "Confirm Update!";
        }
        else if (modalType === 'add') {
            message = "Confirm Add!";
        }
        return message;
    };

    const handleCancel = () => {
        setIsLoading(false);
        setIsConfirmModalVisible(false);
    };

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === 'delete') {
            modalContent = (
                <p>Are you sure you want to delete <b>{formUpdateValues.countryName}</b> Country's Delete request? Once deleted, this country will not be recovered. Do you wish to proceed?</p>
            );
        } else if (modalType === 'update') {
            modalContent = (
                <p>Are you sure you want to update <b>{formUpdateValues.countryName}</b> Country's Update request? Once updated, this country can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        else if (modalType === 'add') {
            modalContent = (
                <p>Are you sure you want to add <b>{formUpdateValues.countryName}</b> Country's Add request? Once added, this country can be edited and their details will be updated accordingly. Do you wish to proceed?</p>
            );
        }
        return modalContent;
    };

    const onFinish = (values: ICountry) => {
        setModalType(((isCountryEdited.current) ? 'update' : 'add'));
        if (modalType === 'update') {
            formInitialValues.current = { countryName: values.countryName, countryCode: values.countryCode, dialingCode: values.dialingCode, isServiceable: values.isServiceable }
            formUpdatedValues.current = { countryName: editingCountry?.CountryName, countryCode: editingCountry?.CountryCode, dialingCode: editingCountry?.DialingCode, isServiceable: editingCountry?.isServiceable }
            if (compareObjects(formInitialValues.current, formUpdatedValues.current)) {
                onClose();
                setIsConfirmModalVisible(false);
                return;
            }
        }
        setIsConfirmModalVisible(true);
        setFormUpdateValues(values);
    };

    const onEditClick = (record: IEditCountry) => {
        setEditingCountry(record);
        isCountryEdited.current = true;
        form.setFieldsValue({
            countryId: record.key, // Include countryId here
            countryName: record.CountryName,
            countryCode: record.CountryCode,
            dialingCode: record.DialingCode,
            isServiceable: record.isServiceable
        });
        setOpen(true);
        eventEmitter.emit("clickableChange", true);
    };

    const data = country.map((item) => ({
        key: item.countryId,
        CountryName: item.countryName,
        CountryCode: item.countryCode,
        DialingCode: item.dialingCode.toString(),
        isServiceable: item.isServiceable ?? false
    }));

    const onSearch = (value: any) => {
        getCountryList(value, "");
        searchValue.current = value;
    };

    return (
        <div>
            <div className="flex justify-between align-middle pb-5">
                <h2 className="text-2xl font-semibold text-black">Countries</h2>
                <div className="flex justify-between gap-5">
                    <div className="w-80 max-w-80">
                        <Search
                            placeholder="Search country"
                            size="large"
                            enterButton
                            className="search-field"
                            onSearch={onSearch}
                        />
                    </div>
                    <Button className="h-10 bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out" onClick={showDrawer} icon={<PlusOutlined />}>
                        Add Country
                    </Button>
                </div>
            </div>
            <Drawer
                title={isCountryEdited.current ? "Edit Country" : "Add New Country"}
                width={370}
                onClose={onClose}
                open={open}
                mask={false}
                loading={isLoading}
            >
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="countryId"
                                label="Country ID"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="countryName"
                                label="Country Name"
                                rules={[{ required: true, message: 'Please enter your country name' }]}
                            >
                                <Input size="large" placeholder="Please enter your country name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="countryCode"
                                label="Country Code"
                                rules={[{ required: true, message: 'Please enter your country code' }]}
                            >
                                <Input size="large" placeholder="Please enter your country code" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="dialingCode"
                                label="Dialing Code"
                                rules={[{ required: true, message: 'Please enter your dialing code' }]}
                            >
                                <Input size="large" placeholder="Please enter your dialing code" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="isServiceable" valuePropName="checked">
                                <Checkbox className="check-box">Serviceable</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Space className="w-full justify-end">
                        <Button onClick={onClose} className="hover:!text-black hover:!border-black">Cancel</Button>
                        <Button htmlType="submit" className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                            {isCountryEdited.current ? "Update" : "Add"}
                        </Button>
                    </Space>
                </Form>
            </Drawer>
            <Table dataSource={data} className="interests-table" bordered
                locale={{
                    emptyText: isLoading ? <Spin /> : (
                        <div className="ant-empty">
                            <div className="ant-empty-image">
                                <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                        <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                        <g fillRule="nonzero" stroke="#d9d9d9">
                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                            <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="ant-empty-description">No data</div>
                        </div>
                    )
                }}>
                <Column title="Country Name" dataIndex="CountryName" key="CountryName" align="center" />
                <Column title="Country Code" dataIndex="CountryCode" key="CountryCode" align="center" />
                <Column title="Dialing Code" dataIndex="DialingCode" key="DialingCode" align="center" />
                <Column
                    title="Serviceable Countries"
                    dataIndex="isServiceable"
                    key="isServiceable"
                    render={(isServiceable) => (
                        <Checkbox className="check-box non-interactive-checkbox" checked={isServiceable ?? false} />
                    )}
                    align="center"
                />
                <Column
                    align="center"
                    title="Action"
                    key="action"
                    render={(_: any, record: any) => (
                        <Space size="middle">
                            <Button icon={<EditOutlined />} className="hover:!border-black hover:!text-black" onClick={() => onEditClick(record)}></Button>
                            <Button icon={<DeleteOutlined />} className="hover:!border-black hover:!text-black" onClick={() => onDeleteCountry(record)}></Button>
                        </Space>
                    )}
                />
            </Table>

            <Modal
                title={<span className="custom-modal-title">{getModalTitle()}</span>}
                open={isConfirmModalVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                footer={[
                    <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                        Cancel
                    </Button>,
                    <Button key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                        Confirm
                    </Button>,
                ]}
            >
                {getModelContent()}
            </Modal>
        </div>
    );
}
export default Countries;
