import React, { useEffect, useRef, useState } from "react";
import { Input, Table, Tabs, ConfigProvider, message, Spin, Tag } from "antd";
import Column from "antd/es/table/Column";
import { formatDateTime, isNotNullUndefined } from "../helpers/common";
import { useFetchTripList } from "../services/trip.service";
import Pagination from "../helpers/pagination";
import { storage } from "../utils/storage";
import { useNavigate } from "react-router-dom";

const Triplist: React.FC = () => {

    const { Search } = Input;
    const { TabPane } = Tabs;
    const navigate = useNavigate();
    const [tripListData, setTripList] = useState([]);
    const tabFilterType = useRef('ALL');
    const pageNumber = useRef(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setIsLoading] = useState(false);
    const searchValue = useRef<string>("");

    const tabItems: any = [
        {
            key: 1,
            name: 'All Trips',
            value: 'All'
        },
        {
            key: 2,
            name: 'Pending Trips',
            value: 'Pending'
        },
        {
            key: 3,
            name: 'Upcoming Trips',
            value: 'Upcoming',
        },
        {
            key: 4,
            name: 'Running Trips',
            value: 'Running'
        },
        {
            key: 5,
            name: 'Completed Trips',
            value: 'Completed'
        }
    ];

    useEffect(() => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        if (userRoleId !== 1) {
            navigate("/");
        }
        else {
            fetchTripList(pageNumber.current, pageSize, tabFilterType.current, searchValue.current ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabFilterType.current, pageSize, searchValue.current]);

    const fetchTripList = async (pageNumber: number, pageSize: number, tabFilterType: string, searchValue: string) => {
        setIsLoading(true);
        setTripList([]);
        fetchTripListMutation.mutate({ pageNumber: pageNumber, pageSize: pageSize, filterType: tabFilterType, searchFilter: searchValue });
    };

    const fetchTripListMutation = useFetchTripList({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                if (isNotNullUndefined(response.data) && response.data.length > 0) {
                    const formattedData = response.data.map((data: any) => ({
                        ...data,
                        tripStartDate: formatDateTime(data.tripStartDate, "DD/MM/YYYY"),
                        tripEndDate: formatDateTime(data.tripEndDate, "DD/MM/YYYY")
                    }));
                    setTripList(formattedData);
                    const recordCount = formattedData[0].recordCount;
                    setTotalPages(Math.ceil(recordCount / pageSize));
                    setIsLoading(false);
                } else {
                    setTripList([]);
                    setIsLoading(false);
                }
            } else {
                message.error(response.statusMessage);
                setIsLoading(false);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
            setIsLoading(false);
        }
    });

    const tabClicked = (key: number) => {
        tabFilterType.current = tabItems.find((x: any) => x.key === key)?.value ?? 'ALL';
        pageNumber.current = 1;
        fetchTripList(pageNumber.current, pageSize, tabFilterType.current, searchValue.current ?? "");
    };

    const handlePageChange = (newPage: number) => {
        pageNumber.current = newPage;
        let size = pageSize;
        setPageSize(size);
        fetchTripList(pageNumber.current, pageSize, tabFilterType.current, searchValue.current ?? "");
    };

    const onRowClick = (record: any) => {
        navigate(`/trips/${record.tripId}`);
    };

    const onSearch = (value: any) => {
        pageNumber.current = 1;
        setPageSize(10);
        searchValue.current = value;
        fetchTripList(pageNumber.current, pageSize, tabFilterType.current, value);
    };

    return (
        <div>
            <div className="flex justify-between align-middle pb-5">
                <h2 className="text-3xl font-semibold text-black">Trip List</h2>
                <div className="flex justify-between gap-5">
                    <div className="w-80 max-w-80">

                        <Search
                            placeholder="Search trip"
                            size="large"
                            enterButton
                            className="search-field"
                            onSearch={onSearch}
                        />
                    </div>
                </div>
            </div>
            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            titleFontSizeLG: 16,
                        },
                    }
                }}
            >
                <Tabs defaultActiveKey="1" onTabClick={(e) => { tabClicked(parseInt(e)) }}>
                    <TabPane tab="All Trips" key="1">
                        <Table dataSource={tripListData} className="userlist-tabs-table cursor-pointer" bordered pagination={{ pageSize: pageSize, position: ['none'] }}
                            onRow={(record) => ({
                                onClick: () => onRowClick(record)
                            })}
                            locale={{
                                emptyText: loading ? <Spin /> : (
                                    <div className="ant-empty">
                                        <div className="ant-empty-image">
                                            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                    <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                    <g fillRule="nonzero" stroke="#d9d9d9">
                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="ant-empty-description">No data</div>
                                    </div>
                                )
                            }}
                        >
                            <Column title="Traveller Name" width={200} dataIndex="userName" key="userName" align="center" />
                            <Column title="Attendant Name" width={200} dataIndex="agentName" key="agentName" align="center" />
                            <Column title="Cities" width={300} dataIndex="cities" key="cities" align="center" />
                            {/* <Column title="Trip Cost" dataIndex="tripCost" key="tripCost" align="center" /> */}
                            <Column title="Trip Status" width={200} dataIndex="tripStatus" key="tripStatus" align="center"
                                render={(tripStatus: string) => (
                                    <>
                                        {tripStatus ?
                                            <Tag key={tripStatus} color={tripStatus === "Completed" ? "green" : tripStatus === "Pending" ? "orange" : tripStatus === "Upcoming" ? "blue" : tripStatus === "Running" ? "purple" : tripStatus === "Expired" ? "magenta" : "red"} className="m-0 pl-1 pr-1">
                                                {tripStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Payment Status" width={200} dataIndex="paymentStatus" key="paymentStatus" align="center"
                                render={(paymentStatus: string) => (
                                    <>
                                        {paymentStatus ?
                                            <Tag key={paymentStatus} color={paymentStatus === "Platform Fee Paid" ? "blue" : paymentStatus === "Fully Paid" ? "green" : paymentStatus === "Partially Paid" ? "purple" : "orange"} className="m-0 pl-1 pr-1">
                                                {paymentStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Trip Start Date" width={200} dataIndex="tripStartDate" key="tripStartDate" align="center" />
                            <Column title="Trip End Date" width={200} dataIndex="tripEndDate" key="tripEndDate" align="center" />
                            {/* <Column title="Review" dataIndex="Review" key="Review" align="center" className="pointer-events-none"
                                render={() => (
                                    <Rate defaultValue={3} disabled />
                                )}
                            /> */}
                        </Table>
                        <div>
                            <Pagination
                                currentPage={pageNumber.current}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Pending Trips" key="2">
                        <Table dataSource={tripListData} className="userlist-table cursor-pointer" bordered pagination={{ pageSize: pageSize, position: ['none'] }}
                            onRow={(record) => ({
                                onClick: () => onRowClick(record)
                            })}
                            locale={{
                                emptyText: loading ? <Spin /> : (
                                    <div className="ant-empty">
                                        <div className="ant-empty-image">
                                            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                    <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                    <g fillRule="nonzero" stroke="#d9d9d9">
                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="ant-empty-description">No data</div>
                                    </div>
                                )
                            }}
                        >
                            <Column title="Traveller Name" dataIndex="userName" key="userName" align="center" />
                            <Column title="Attendant Name" dataIndex="agentName" key="agentName" align="center" />
                            <Column title="Cities" dataIndex="cities" key="cities" align="center" />
                            {/* <Column title="Trip Cost" dataIndex="tripCost" key="tripCost" align="center" /> */}
                            <Column title="Trip Status" width={200} dataIndex="tripStatus" key="tripStatus" align="center"
                                render={(tripStatus: string) => (
                                    <>
                                        {tripStatus ?
                                            <Tag key={tripStatus} color={tripStatus === "Completed" ? "green" : tripStatus === "Pending" ? "orange" : tripStatus === "Upcoming" ? "blue" : tripStatus === "Running" ? "purple" : tripStatus === "Expired" ? "magenta" : "red"} className="m-0 pl-1 pr-1">
                                                {tripStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Payment Status" width={200} dataIndex="paymentStatus" key="paymentStatus" align="center"
                                render={(paymentStatus: string) => (
                                    <>
                                        {paymentStatus ?
                                            <Tag key={paymentStatus} color={paymentStatus === "Platform Fee Paid" ? "blue" : paymentStatus === "Fully Paid" ? "green" : paymentStatus === "Partially Paid" ? "purple" : "orange"} className="m-0 pl-1 pr-1">
                                                {paymentStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Trip Start Date" dataIndex="tripStartDate" key="tripStartDate" align="center" />
                            <Column title="Trip End Date" dataIndex="tripEndDate" key="tripEndDate" align="center" />
                            {/* <Column title="Review" dataIndex="Review" key="Review" align="center" className="pointer-events-none"
                                render={() => (
                                    <Rate defaultValue={3} disabled />
                                )}
                            /> */}
                        </Table>
                        <div>
                            <Pagination
                                currentPage={pageNumber.current}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Upcoming Trips" key="3">
                        <Table dataSource={tripListData} className="userlist-table cursor-pointer" bordered pagination={{ pageSize: pageSize, position: ['none'] }}
                            onRow={(record) => ({
                                onClick: () => onRowClick(record)
                            })}
                            locale={{
                                emptyText: loading ? <Spin /> : (
                                    <div className="ant-empty">
                                        <div className="ant-empty-image">
                                            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                    <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                    <g fillRule="nonzero" stroke="#d9d9d9">
                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="ant-empty-description">No data</div>
                                    </div>
                                )
                            }}
                        >
                            <Column title="Traveller Name" dataIndex="userName" key="userName" align="center" />
                            <Column title="Attendant Name" dataIndex="agentName" key="agentName" align="center" />
                            <Column title="Cities" dataIndex="cities" key="cities" align="center" />
                            {/* <Column title="Trip Cost" dataIndex="tripCost" key="tripCost" align="center" /> */}
                            <Column title="Trip Status" width={200} dataIndex="tripStatus" key="tripStatus" align="center"
                                render={(tripStatus: string) => (
                                    <>
                                        {tripStatus ?
                                            <Tag key={tripStatus} color={tripStatus === "Completed" ? "green" : tripStatus === "Pending" ? "orange" : tripStatus === "Upcoming" ? "blue" : tripStatus === "Running" ? "purple" : tripStatus === "Expired" ? "magenta" : "red"} className="m-0 pl-1 pr-1">
                                                {tripStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Payment Status" width={200} dataIndex="paymentStatus" key="paymentStatus" align="center"
                                render={(paymentStatus: string) => (
                                    <>
                                        {paymentStatus ?
                                            <Tag key={paymentStatus} color={paymentStatus === "Platform Fee Paid" ? "blue" : paymentStatus === "Fully Paid" ? "green" : paymentStatus === "Partially Paid" ? "purple" : "orange"} className="m-0 pl-1 pr-1">
                                                {paymentStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Trip Start Date" dataIndex="tripStartDate" key="tripStartDate" align="center" />
                            <Column title="Trip End Date" dataIndex="tripEndDate" key="tripEndDate" align="center" />
                            {/* <Column title="Review" dataIndex="Review" key="Review" align="center" className="pointer-events-none"
                                render={() => (
                                    <Rate defaultValue={3} disabled />
                                )}
                            /> */}
                        </Table>
                        <div>
                            <Pagination
                                currentPage={pageNumber.current}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Running Trips" key="4">
                        <Table dataSource={tripListData} className="userlist-table cursor-pointer" bordered pagination={{ pageSize: pageSize, position: ['none'] }}
                            onRow={(record) => ({
                                onClick: () => onRowClick(record)
                            })}
                            locale={{
                                emptyText: loading ? <Spin /> : (
                                    <div className="ant-empty">
                                        <div className="ant-empty-image">
                                            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                    <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                    <g fillRule="nonzero" stroke="#d9d9d9">
                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="ant-empty-description">No data</div>
                                    </div>
                                )
                            }}
                        >
                            <Column title="Traveller Name" dataIndex="userName" key="userName" align="center" />
                            <Column title="Attendant Name" dataIndex="agentName" key="agentName" align="center" />
                            <Column title="Cities" dataIndex="cities" key="cities" align="center" />
                            {/* <Column title="Trip Cost" dataIndex="tripCost" key="tripCost" align="center" /> */}
                            <Column title="Trip Status" width={200} dataIndex="tripStatus" key="tripStatus" align="center"
                                render={(tripStatus: string) => (
                                    <>
                                        {tripStatus ?
                                            <Tag key={tripStatus} color={tripStatus === "Completed" ? "green" : tripStatus === "Pending" ? "orange" : tripStatus === "Upcoming" ? "blue" : tripStatus === "Running" ? "purple" : tripStatus === "Expired" ? "magenta" : "red"} className="m-0 pl-1 pr-1">
                                                {tripStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Payment Status" width={200} dataIndex="paymentStatus" key="paymentStatus" align="center"
                                render={(paymentStatus: string) => (
                                    <>
                                        {paymentStatus ?
                                            <Tag key={paymentStatus} color={paymentStatus === "Platform Fee Paid" ? "blue" : paymentStatus === "Fully Paid" ? "green" : paymentStatus === "Partially Paid" ? "purple" : "orange"} className="m-0 pl-1 pr-1">
                                                {paymentStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Trip Start Date" dataIndex="tripStartDate" key="tripStartDate" align="center" />
                            <Column title="Trip End Date" dataIndex="tripEndDate" key="tripEndDate" align="center" />
                            {/* <Column title="Review" dataIndex="Review" key="Review" align="center" className="pointer-events-none"
                                render={() => (
                                    <Rate defaultValue={3} disabled />
                                )}
                            /> */}
                        </Table>
                        <div>
                            <Pagination
                                currentPage={pageNumber.current}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Completed Trips" key="5">
                        <Table dataSource={tripListData} className="userlist-table cursor-pointer" bordered pagination={{ pageSize: pageSize, position: ['none'] }}
                            onRow={(record) => ({
                                onClick: () => onRowClick(record)
                            })}
                            locale={{
                                emptyText: loading ? <Spin /> : (
                                    <div className="ant-empty">
                                        <div className="ant-empty-image">
                                            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                    <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                    <g fillRule="nonzero" stroke="#d9d9d9">
                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="ant-empty-description">No data</div>
                                    </div>
                                )
                            }}
                        >
                            <Column title="Traveller Name" dataIndex="userName" key="userName" align="center" />
                            <Column title="Attendant Name" dataIndex="agentName" key="agentName" align="center" />
                            <Column title="Cities" dataIndex="cities" key="cities" align="center" />
                            {/* <Column title="Trip Cost" dataIndex="tripCost" key="tripCost" align="center" /> */}
                            <Column title="Trip Status" width={200} dataIndex="tripStatus" key="tripStatus" align="center"
                                render={(tripStatus: string) => (
                                    <>
                                        {tripStatus ?
                                            <Tag key={tripStatus} color={tripStatus === "Completed" ? "green" : tripStatus === "Pending" ? "orange" : tripStatus === "Upcoming" ? "blue" : tripStatus === "Running" ? "purple" : tripStatus === "Expired" ? "magenta" : "red"} className="m-0 pl-1 pr-1">
                                                {tripStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Payment Status" width={200} dataIndex="paymentStatus" key="paymentStatus" align="center"
                                render={(paymentStatus: string) => (
                                    <>
                                        {paymentStatus ?
                                            <Tag key={paymentStatus} color={paymentStatus === "Platform Fee Paid" ? "blue" : paymentStatus === "Fully Paid" ? "green" : paymentStatus === "Partially Paid" ? "purple" : "orange"} className="m-0 pl-1 pr-1">
                                                {paymentStatus.toUpperCase()}
                                            </Tag> : ""}
                                    </>
                                )} />
                            <Column title="Trip Start Date" dataIndex="tripStartDate" key="tripStartDate" align="center" />
                            <Column title="Trip End Date" dataIndex="tripEndDate" key="tripEndDate" align="center" />
                            {/* <Column title="Review" dataIndex="Review" key="Review" align="center" className="pointer-events-none"
                                render={() => (
                                    <Rate defaultValue={3} disabled />
                                )}
                            /> */}
                        </Table>
                        <div>
                            <Pagination
                                currentPage={pageNumber.current}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </ConfigProvider>
        </div>
    );
};

export default Triplist;
// Helper function to convert file to base64

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
}