import { get, patch, post } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useFetchStates = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("state/list?countryId=" + data.countryId + "&searchFilter=" + data.searchFilter);
    }, options);
};

export const useAddState = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (newState: any) => {
        return await post("state", newState);
    }, options);
};

export const useDeleteState = (options: any) => {
    return useMutation(async (stateId) => {
        return await patch(`state/${stateId}/delete`, {});
    }, options);
};