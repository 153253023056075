import { Row, Col, Flex, Button, Form, Input, message } from 'antd';
import Images from '../assets/image/index';
import { storage } from '../utils/storage';
import { useEffect, useState } from 'react';
import { isNotNullUndefined, isNotNullUndefinedBlank } from '../helpers/common';
import { useNavigate } from 'react-router-dom';
import { useFetchUser, useSendVerificationCode, useUpdateVerifiedStatus, useVerifyUserVerificationCode } from '../services/user.service';
import { useTimer } from '../helpers/useTimer';
import { useUpdateAgentStatus } from '../services/agent.service';
import IResponse from '../types/response';
import { useUpdateUserStatus } from '../services/traveller.service';

const Authorization = () => {
    const email = storage.getEmailId() ?? "";
    const codeType = storage.getCodeType() ?? "";
    const mobileNumber = storage.getPhoneNumber() ?? "";
    const dialingCode = storage.getDialingCode() ?? "";
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { resendTimer, resetTimer } = useTimer();

    const [form] = Form.useForm();

    useEffect(() => {
        if (!isNotNullUndefinedBlank(email) && !isNotNullUndefinedBlank(mobileNumber)) {
            navigate("/");
        }
        // else if ((storage.getToken() ?? "") !== "" && (storage.getRefreshToken() ?? "") !== "") {
        //     fetchUser();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOtpChange = (event: any) => {
        const isNumberKey = !isNaN(event.key);
        const children = event.currentTarget.children;
        const targetIndex = Array.prototype.indexOf.call(children, event.target);
        let newValue = '';
        Array.prototype.forEach.call(children, (child, index) => {
            if (index === targetIndex) {
                newValue += event.key;
            } else {
                newValue += child.defaultValue;
            }
        });
        const isValidInput = !isNaN(Number(newValue));
        if (!isNumberKey || !isValidInput) {
            if (event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey) {
                if (/[a-zA-Z\W]/.test(event.key)) {
                    message.error('OTP should only contain numbers.');
                    event.preventDefault();
                }
            }
            return false;
        }
    };

    const onChangeEmailAddress = () => {
        if (storage.getPreviousUrlPath() === "/forgotpassword") {
            navigate("/forgotpassword");
        } else {
            navigate("/registration");
        }
    };

    const onVerify = async () => {
        const code = form.getFieldValue("otpCheck");

        let verificationCodeType = codeType;
        if (email) {
            verificationCodeType = "email";
        } else if (mobileNumber) {
            verificationCodeType = "sms";
        }
        if (isNotNullUndefinedBlank(code) && code.length === 6) {
            setIsLoading(true);
            verifiyVerificationCodeMutation.mutate({ userEmail: email, phoneNumber: mobileNumber, verificationCode: code, codeType: verificationCodeType });
        } else {
            message.warning("OTP must be 6 digits long.");
        }
    };

    const onUpdateVerifiedStatus = async () => {
        let verificationCodeType = codeType;
        if (email) {
            verificationCodeType = "email";
        } else if (mobileNumber) {
            verificationCodeType = "sms";
        }
        updateVerifiedStatusMutation.mutate({ userId: storage.getUserId() ?? "", email: storage.getEmailId() ?? "", codeType: verificationCodeType });
    };

    const onResend = () => {
        sendVerificationCodeMutation.mutate({
            userEmail: email,
            phoneNumber: mobileNumber,
            phoneNoDialingCode: codeType === "sms" ? storage.getDialingCode() : ""
        });
        resetTimer();
    };

    const sendVerificationCodeMutation = useSendVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                message.success("OTP has been sent successfully.");
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const updateVerifiedStatusMutation = useUpdateVerifiedStatus({
        onSuccess: (response: any, request) => {
            if (response.statusCode === 200) {
                const userRoleId = storage.getUserRoleId();
                const typeVerified = request.codeType === "email" ? "Email Verified" : "Phone Number Verified";
                const role = userRoleId === '2' ? "Attendant" : userRoleId === '3' ? "Traveller" : "";
                message.success(`${role} ${typeVerified}`);
            } else {
                message.error(response.statusMessage);
            }
        }, onError: (error) => {
            message.error(error.statusMessage);
        }
    });

    const verifiyVerificationCodeMutation = useVerifyUserVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                message.success(response.statusMessage);
                storage.setToken(response.data.token);
                storage.setRefreshToken(response.data.refreshToken);
                storage.setUserRoleId(response.data.userRoleId);
                fetchUser();
            }
            else {
                setIsLoading(false);
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        }
    });

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const handleAgentNavigation = (statusId: any, userId: string, previousUrlPath: string) => {
        const code = form.getFieldValue("otpCheck") ?? "";
        if (statusId === 10 && code.trim().length === 6) {
            updateAgentStatus.mutate({ agentId: userId, statusCode: "OTP_VERIFIED", reason: "" });
        } else if ((statusId === 7 || statusId === 6 || statusId === 1) && previousUrlPath !== '/forgotpassword') {
            navigate("/complete");
        }
        // else {
        //     if (window.location.pathname === "/authorization") {
        //         sendVerificationCodeMutation.mutate({
        //             userId: storage.getUserId() ?? "",
        //             userEmail: email,
        //             phoneNumber: mobileNumber,
        //             phoneNoDialingCode: codeType === "sms" ? storage.getDialingCode() : ""
        //         });
        //     }
        //     navigate(statusWiseRedirectToPage());
        // }
    }

    const handleTravellerNavigation = (statusId: any, userId: string) => {
        const code = form.getFieldValue("otpCheck") ?? "";
        if (statusId === 10 && code.trim().length === 6) {
            updateUserStatus.mutate({ travellerId: userId, statusCode: "OTP_VERIFIED", reason: "" });
        } else if (statusId === 8 || statusId === 7 || statusId === 6 || statusId === 1) {
            navigate("/complete");
        }
        // else {
        //     if (window.location.pathname === "/authorization") {
        //         sendVerificationCodeMutation.mutate({
        //             userId: storage.getUserId() ?? "",
        //             userEmail: email,
        //             phoneNumber: mobileNumber,
        //             phoneNoDialingCode: codeType === "sms" ? storage.getDialingCode() : ""
        //         });
        //     }
        //     navigate(statusWiseRedirectToPage());
        // }
    }

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                const previousUrlPath = storage.getPreviousUrlPath() ?? "";
                const { userRoleId, statusId, email, userId } = response.data;
                storage.setUserStatusId(statusId);
                storage.setUserRoleId(userRoleId);
                storage.setUserId(userId);
                if (previousUrlPath === '/forgotpassword' || userRoleId === 1) {
                    storage.setEmailId(email);
                    navigate('/resetpassword');
                } else if (userRoleId === 2) {
                    handleAgentNavigation(statusId, userId, previousUrlPath);
                } else if (userRoleId === 3 && previousUrlPath !== '/forgotpassword') {
                    handleTravellerNavigation(statusId, userId);
                }
            } else {
                setIsLoading(false);
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            setIsLoading(false);
            message.error(error.message);
        }
    });

    const updateUserStatus = useUpdateUserStatus({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                onUpdateVerifiedStatus();
                navigate("/completeprofile");
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const updateAgentStatus = useUpdateAgentStatus({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                onUpdateVerifiedStatus();
                navigate("/completeprofile");
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    return (
        <div className="app-container">
            <Row className="min-h-screen">
                <Col span={12} className="hidden lg:block bg-black h-screen">
                    <div className='h-screen bg-login-background bg-no-repeat bg-cover flex justify-center items-center'>
                        <div>
                            <div className='flex justify-center w-full'>
                                <div className='w-72'>
                                    <img src={Images.triplogo} className='w-full' alt="triplogo" />
                                </div>
                            </div>
                            <div className='mt-20 flex justify-center'>
                                <img src={Images.travellogo} className='w-[335px] 2xl:w-[443px]' alt="travel-logo" />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={24} lg={12}>
                    <div className='block  lg:w-0 lg:h-0 lg:hidden'>
                        <div className='flex justify-center py-16'>
                            <img src={Images.mobilelogo} className='w-[140px] h-[60px] lg:w-[219px] lg:h-[96px]' alt="triplogo" />
                        </div>
                    </div>
                    <Form
                        form={form}
                        onSubmitCapture={onVerify}
                    >
                        <div className='flex justify-center items-center lg:h-screen'>
                            <div className="p-6 w-full max-w-md">
                                <div>
                                    <p className="text-center text-[32px] font-extrabold mb-6 text-black">Verify Your Authorization</p>
                                    <p className='text-center text-base mb-5'>Enter the 6-digit verification code sent to
                                        {codeType === "both" ? (" " + dialingCode + " " + mobileNumber + " and " + email + " ") : codeType === "sms" ? (" " + dialingCode + " " + mobileNumber + " ") : (" " + email + " ")}
                                        to confirm your authorization.</p>
                                    <Flex gap="middle" justify='center' className='otp-box' vertical>
                                        <Form.Item name="otpCheck">
                                            <Input.OTP id='otpNumber' onKeyDown={(event) => handleOtpChange(event)} />
                                        </Form.Item>
                                    </Flex>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="h-14 w-full mt-6 text-xl bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out cursor-pointer"
                                            loading={isLoading}>
                                            Agreed & proceed
                                        </Button>
                                    </Form.Item>
                                    {/* <a className="text-sm font-semibold text-center text-gray-500 mt-2 block">Resend otp in 30s </a> */}
                                    <div className="text-sm font-semibold text-center text-gray-500 mt-2 block">
                                        {resendTimer > 0 ? (
                                            <p className="font-semibold text-center text-gray-400 mt-2 block">
                                                Resend OTP in {resendTimer}s
                                            </p>
                                        ) : (
                                            <button onClick={onResend} type="button"
                                                className="font-semibold text-center text-gray-500 border-none">
                                                Resend OTP
                                            </button>
                                        )}
                                    </div>
                                    <button className="text-base font-normal w-full mt-5 border-none text-black text-center underline block hover:text-black"
                                        onClick={onChangeEmailAddress}>
                                        Change email address
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default Authorization;