import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchUser } from "../services/user.service";
import IResponse from "../types/response";
import { storage } from "../utils/storage";
import { useAuthenticate } from "../services/login.service";
import { message } from "antd";

const FacebookRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlHashParams = new URLSearchParams(window.location.hash.slice(1));
        const access_token = urlHashParams.get('access_token');

        if (access_token) {
            facebookLogin.mutate({ loginType: "facebook", accessToken: access_token, userRoleId: parseInt(storage.getUserRoleId() ?? "") });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                navigate("/completeprofile");
            }
            else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const facebookLogin = useAuthenticate({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                storage.setToken(response.data.token);
                storage.setRefreshToken(response.data.refreshToken);
                storage.setUserRoleId(response.data.userRoleId);
                fetchUser();
            }
            else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    return (
        <button type="button" className="bg-indigo-500 ..." disabled>
            <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
            </svg>
            Processing...
        </button>
    );
};

export default FacebookRedirect;