import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchUser, useUpdateVerifiedStatus } from "../services/user.service";
import IResponse from "../types/response";
import { storage } from "../utils/storage";
import { useAuthenticate } from "../services/login.service";
import { message } from "antd";
import { statusWiseRedirectToPage } from "../helpers/common";

const GoogleRedirect = () => {
    const navigate = useNavigate();
    const isEmailVerified = useRef(false);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const code = urlSearchParams.get('code');
        if (code) {
            googleRegister.mutate({ code: code, loginType: "google", userRoleId: parseInt(storage.getUserRoleId() ?? "") });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                storage.setUserRoleId(response.data.userRoleId);
                storage.setUserId(response.data.userId);
                if (isEmailVerified.current) {
                    updateVerifiedStatusMutation.mutate({ userId: response.data.userId ?? "", codeType: 'email' });
                }
                if (response.data.statusId > 0) {
                    storage.setUserStatusId(response.data.statusId);
                }
                navigate(statusWiseRedirectToPage());
            }
            else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const googleRegister = useAuthenticate({
        onSuccess: async (response: IResponse, request) => {
            if (response.statusCode === 200) {
                storage.setLoginType(request.loginType);
                storage.setToken(response.data.token);
                storage.setRefreshToken(response.data.refreshToken);
                storage.setUserRoleId(response.data.userRoleId);
                isEmailVerified.current = true;
                await fetchUser();
            }
            else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const updateVerifiedStatusMutation = useUpdateVerifiedStatus({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                isEmailVerified.current = false;
                fetchUser();
            } else {
                message.error(response.statusMessage);
            }
        }, onError: (error) => {
            message.error(error.statusMessage);
        }
    });

    return (
        <button type="button" className="bg-indigo-500 ..." disabled>
            <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
            </svg>
            Processing...
        </button>
    );
};

export default GoogleRedirect;