import { Routes, Route } from "react-router-dom";
import Login from "./components/login";
import Forgotpassword from "./components/forgotpassword";
import Resetpassword from "./components/resetpassword";
import Registration from "./components/registration";
import Completeprofile from "./components/completeprofile";
// import User from "./components/user";
import Mostvisitedplace from "./components/mostvisitedplace";
import Countries from "./components/countries";
import Tripdetails from "./components/tripdetails";
import States from "./components/states";
import Cities from "./components/cities";
import Triplist from "./components/triplist";
//import Ports from "./components/ports";
// import Interests from "./components/interests"
import MainLayout from "./components/mainlayout";
import { ConfigProvider } from "antd";
import Authorization from "./components/authorization";
import GoogleRedirect from "./components/googleredirect";
import FacebookRedirect from "./components/facebookredirect";
import DownloadApplication from "./components/downloadapplication";
import AgentDocument from "./components/agentdocument";
import Attendants from "./components/attendants";
import Traveller from "./components/traveller";
import GeneralSetting from "./components/generalsetting";
import Chats from "./components/chats";
import { useState, useEffect } from "react";
import { eventEmitter } from "./helpers/eventemitter";

function App() {

  const [isClickable, setIsClickable] = useState(false);

  useEffect(() => {
    const handleClickableChange = (newValue: boolean) => {
      setIsClickable(newValue);
    };
    eventEmitter.on("clickableChange", handleClickableChange);

    return () => {
      eventEmitter.off("clickableChange", handleClickableChange);
    };

  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Jost,Kalam"
        }
      }}
    >
      <div className={`App ${isClickable ? "disable-clicks" : ""}`}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="forgotpassword" element={<Forgotpassword />} />
          <Route path="resetpassword" element={<Resetpassword />} />
          <Route path="authorization" element={<Authorization />} />
          <Route path="registration" element={<Registration />} />
          <Route path="auth/google" element={<GoogleRedirect />} />
          <Route path="auth/facebook" element={<FacebookRedirect />} />
          <Route path="complete" element={<DownloadApplication />} />
          <Route path="completeprofile" element={<Completeprofile />} />
          <Route path="agentdocument" element={<AgentDocument />} />
          <Route path="attendants" element={<MainLayout><Attendants /></MainLayout>} />
          <Route path="travellers" element={<MainLayout><Traveller /></MainLayout>} />
          <Route path="mostvisitedplaces" element={<MainLayout>< Mostvisitedplace /></MainLayout>} />
          <Route path="countries" element={<MainLayout><Countries /></MainLayout>} />
          <Route path="states" element={<MainLayout><States /></MainLayout>} />
          <Route path="cities" element={<MainLayout><Cities /></MainLayout>} />
          <Route path="trips" element={<MainLayout><Triplist /></MainLayout>} />
          <Route path="settings" element={<MainLayout><GeneralSetting /></MainLayout>} />
          <Route path="chats" element={<MainLayout><Chats /></MainLayout>} />
          <Route path="trips/:id" element={<MainLayout><Tripdetails /></MainLayout>} />
          {/* <Route path="ports" element={<MainLayout><Ports /></MainLayout>} /> */}
          {/* <Route path="interests" element={<MainLayout><Interests /></MainLayout>} /> */}
          {/* <Route path="user" element={<MainLayout><User /></MainLayout>} /> */}
          {/* <Route path="forgotpassword" element={<Forgotpassword />} /> */}
        </Routes>
      </div>
    </ConfigProvider>
  );
}

export default App;