import { useEffect, useRef } from 'react';
import { IUserDetail } from '../types/user';
import { storage } from "../utils/storage";
import { useFetchUser } from "../services/user.service";
import { Dropdown, Button, Menu, Layout, message } from 'antd';
import { UserOutlined, QuestionOutlined } from '@ant-design/icons';
import Images from "../assets/image";
import { useNavigate } from "react-router-dom";
import IResponse from '../types/response';
import { isNotNullUndefined, isNotNullUndefinedBlank } from "../helpers/common";


const DownloadApplication = () => {

    const user = useRef<IUserDetail>({
        userId: "",
        userRoleId: 0,
        statusId: 0,
        isAgentDocumentExists: 0,
        rejectionReason: ""
    }).current;

    useEffect(() => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        if (userRoleId === 1 || userRoleId === 0) {
            navigate("/");
        }
        else if (isNotNullUndefinedBlank(storage.getToken() ?? "")) {
            fetchUser();
            storage.setPreviousUrlPath(window.location.pathname);
            storage.clearCodeType();
            storage.clearPhoneNumber();
            storage.clearCountryId();
            storage.clearDialingCode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                user.userId = response.data.userId;
                user.userRoleId = response.data.userRoleId;
                user.statusId = response.data.statusId;
                storage.setUserStatusId(user.statusId);
                user.isAgentDocumentExists = response.data.isAgentDocumentExists;
                user.rejectionReason = response.data.rejectReason;
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const { Header } = Layout;
    const navigate = useNavigate();

    const logOut = async () => {
        window.localStorage.clear();
        navigate('/');
    };

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={logOut}>Log Out</Menu.Item>
        </Menu>
    );

    return (
        <div className="h-screen flex flex-col">
            <Header className="user-profile-btn flex items-center p-0 bg-black h-[75px]">
                <div className="trip-logo">
                    <div className="p-2 h-[73px]">
                        <img src={Images.triplogo} alt="header logo" className="h-full w-full object-contain" />
                    </div>
                </div>
                <div className="flex justify-end items-center w-full px-4">
                    <div>
                        <Button
                            icon={<QuestionOutlined />}
                            className="bg-transparent border rounded-full hover:!bg-transparent hover:!border-white mr-3"
                        />
                        <Dropdown overlay={menu} placement="bottomRight">
                            <Button
                                icon={<UserOutlined />}
                                className="bg-transparent border rounded-full hover:!bg-transparent hover:!border-white"
                            />
                        </Dropdown>
                    </div>
                </div>
            </Header>
            {user.userRoleId === 3 && (
                <div>
                    <p className='font-kalam text-center text-[26px] lg:text-[40px] mt-5 lg:mt-20'>Thank you for registering!</p>
                    <div className="flex justify-center lg:mt-14">
                        <div className="w-full sm:w-11/12 xl:w-4/5 xxl:w-3/5 p-8 lg:p-12 bg-white rounded-lg drop-shadow-lg">
                            <p className="font-bold text-lg lg:text-[22px] mb-2">Welcome to Tripattendynt!,</p>
                            <div className="text-gray-700 text-base lg:text-lg leading-8 lg:leading-9 font-jost">
                                <p>Our application is currently available only on mobile devices for traveller and attendant roles. To access the full features of our system, please download our mobile application.</p>
                                <p>Download the mobile app from the Play Store and App Store to enjoy full access and enhanced functionality on the go.</p>
                                <p> We appreciate your understanding and look forward to providing you with a seamless experience on our mobile platform.</p>
                                <p>For a seamless experience, we highly recommend using our mobile application. You can follow the steps below to install the app:</p>
                            </div>
                            <div className='mt-5 font-jost'>
                                <p className='font-semibold text-black text-lg'>Download Our Mobile App:</p>
                                <div className="mt-4 flex flex-col gap-4 md:flex-row md:flex-wrap">
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">1</div>
                                        <p>Click store button below</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">2</div>
                                        <p>Search<span className='font-semibold'>"Tripattendynt"</span></p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">3</div>
                                        <p className='flex items-center gap-2.5'>Locate the
                                            <div className='w-[150px]'>
                                                <img src={Images.smalllogo} className='cursor-pointer w-full' alt="triplogo" />
                                            </div>
                                            & click</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">4</div>
                                        <p><span className='font-semibold'>Click Install to complete process</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-center gap-5 mt-12'>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.googleplay} alt="Google play" />
                                </div>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.app} alt="Google play" />
                                </div>
                            </div>
                            <div className='text-center mt-5 font-jost text-base lg:text-[22px]'>
                                <p>Thank you for your cooperation. We look forward to working with you! </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {user.userRoleId === 2 && user.statusId === 6 && user.isAgentDocumentExists && (
                <div>
                    <p className='font-kalam text-center text-[26px] lg:text-[40px] mt-5 lg:mt-20'>Thank you for registering!</p>
                    <div className="flex justify-center mt-2">
                        <div className="w-full sm:w-11/12 xl:w-4/5 xxl:w-2/3 p-8 lg:p-12 bg-white rounded-lg drop-shadow-lg">
                            <p className="font-bold text-lg lg:text-[22px] mb-2">Welcome to Tripattendynt!,</p>
                            <div className="text-gray-700 text-base lg:text-lg leading-8 lg:leading-9 font-jost">
                                <p>Congratulations on successfully registering with us! We are excited to have you on board.</p>
                                <p><b className='text-black'>Next Steps:</b> Thank you for uploading your documents. Our admin team is currently reviewing them for verification.</p>
                                <p><b className='text-black'>Important:</b> Your account will be in the "Waiting for Approval" state until the screening process is completed. Once your documents are reviewed and approved, you will receive a notification regarding the status of your approval.</p>
                                <p>For a seamless experience, we highly recommend using our mobile application. You can follow the steps below to install the app:</p>
                            </div>
                            <div className='mt-5 font-jost'>
                                <p className='font-semibold text-black text-lg'>Download Our Mobile App:</p>
                                <div className="mt-4 flex flex-col gap-4 md:flex-row md:flex-wrap">
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">1</div>
                                        <p>Click on a store button below</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">2</div>
                                        <p>Try searching <span className='font-semibold'>"Tripattendynt"</span></p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">3</div>
                                        <p className='flex items-center gap-2.5'>Locate the  <div className='w-[150px]'>
                                            <img src={Images.smalllogo} className='cursor-pointer w-full' alt="triplogo" />
                                        </div> & click</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">4</div>
                                        <p><span className='font-semibold'>Click Install to complete process</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-center gap-5 mt-12'>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.googleplay} alt="Google play" />
                                </div>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.app} alt="Google play" />
                                </div>
                            </div>
                            <div className='text-center mt-5 font-jost text-base lg:text-[22px]'>
                                <p>Thank you for your patience. We look forward to working with you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {user.userRoleId === 2 && (user.statusId === 6 || user.statusId === 1) && !user.isAgentDocumentExists && (
                <div>
                    <p className='font-kalam text-center text-[26px] lg:text-[40px] mt-5 lg:mt-20'>Thank you for registering!</p>
                    <div className="flex justify-center mt-2">
                        <div className="w-full sm:w-11/12 xl:w-4/5 xxl:w-2/3 p-8 lg:p-12 bg-white rounded-lg drop-shadow-lg">
                            <p className="font-bold text-lg lg:text-[22px] mb-2">Welcome to Tripattendynt!,</p>
                            <div className="text-gray-700 text-base lg:text-lg leading-8 lg:leading-9 font-jost">
                                <p> Congratulations on successfully registering with us! We are excited to have you on board.</p>
                                <p><b className='text-black'>Next Steps:</b> It looks like you haven't uploaded your documents yet. Please note that submitting your legal documents, such as an identification card, driving license, and address proof, is essential for the screening process.</p>
                                <p><b className='text-black'>Upload Your Documents:</b> To complete your registration, please upload your documents as soon as possible. You can do this by logging into your account and navigating to the "Upload Documents" section.</p>
                                <p><b className='text-black'>Important:</b> Your account will remain in the "Waiting for Approval" state until we receive and verify your documents. Once your documents are reviewed, you will receive a notification regarding the status of your approval.</p>
                                <p>For a seamless experience, we highly recommend using our mobile application. You can follow the steps below to install the app:</p>
                            </div>
                            <div className='mt-5 font-jost'>
                                <p className='font-semibold text-black text-lg'>Download Our Mobile App:</p>
                                <div className="mt-4 flex flex-col gap-4 md:flex-row md:flex-wrap">
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">1</div>
                                        <p>Click on a store button below</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">2</div>
                                        <p>Try searching <span className='font-semibold'>"Tripattendynt"</span></p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">3</div>
                                        <p className='flex items-center gap-2.5'>Locate the  <div className='w-[150px]'>
                                            <img src={Images.smalllogo} className='cursor-pointer w-full' alt="triplogo" />
                                        </div> & click</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">4</div>
                                        <p><span className='font-semibold'>Click Install to complete process</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-center gap-5 mt-12'>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.googleplay} alt="Google play" />
                                </div>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.app} alt="Google play" />
                                </div>
                            </div>
                            <div className='text-center mt-5 font-jost text-base lg:text-[22px]'>
                                <p>Thank you for your cooperation. We look forward to working with you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {user.userRoleId === 2 && user.statusId === 1 && user.isAgentDocumentExists && (
                <div>
                    <p className='font-kalam text-center text-[26px] lg:text-[40px] mt-5 lg:mt-20'>Thank you for registering!</p>
                    <div className="flex justify-center mt-2">
                        <div className="w-full sm:w-11/12 xl:w-4/5 xxl:w-2/3 p-8 lg:p-12 bg-white rounded-lg drop-shadow-lg">
                            <p className="font-bold text-lg lg:text-[22px] mb-2">Welcome to Tripattendynt!,</p>
                            <div className="text-gray-700 text-base lg:text-lg leading-8 lg:leading-9 font-jost">
                                <p>Our application is currently available only on mobile devices for traveller and attendant roles. To access the full features of our system, please download our mobile application.</p>
                                <p>Download the mobile app from the Play Store and App Store to enjoy full access and enhanced functionality on the go.</p>
                                <p> We appreciate your understanding and look forward to providing you with a seamless experience on our mobile platform.</p>
                                <p>For a seamless experience, we highly recommend using our mobile application. You can follow the steps below to install the app:</p>
                            </div>
                            <div className='mt-5 font-jost'>
                                <p className='font-semibold text-black text-lg'>Download Our Mobile App:</p>
                                <div className="mt-4 flex flex-col gap-4 md:flex-row md:flex-wrap">
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">1</div>
                                        <p>Click on a store button below</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">2</div>
                                        <p>Try searching <span className='font-semibold'>"Tripattendynt"</span></p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">3</div>
                                        <p className='flex items-center gap-2.5'>Locate the  <div className='w-[150px]'>
                                            <img src={Images.smalllogo} className='cursor-pointer w-full' alt="triplogo" />
                                        </div> & click</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">4</div>
                                        <p><span className='font-semibold'>Click Install to complete process</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-center gap-5 mt-12'>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.googleplay} alt="Google play" />
                                </div>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.app} alt="Google play" />
                                </div>
                            </div>
                            <div className='text-center mt-5 font-jost text-base lg:text-[22px]'>
                                <p>Thank you for your cooperation. We look forward to working with you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {user.userRoleId === 2 && user.statusId === 100 && user.isAgentDocumentExists && (
                <div>
                    <p className='font-kalam text-center text-[26px] lg:text-[40px] mt-5 lg:mt-20'>Thank you for registering!</p>
                    <div className="flex justify-center mt-2">
                        <div className="w-full sm:w-11/12 xl:w-4/5 xxl:w-2/3 p-8 lg:p-12 bg-white rounded-lg drop-shadow-lg">
                            <p className="font-bold text-lg lg:text-[22px] mb-2">Welcome to Tripattendynt!,</p>
                            <div className="text-gray-700 text-base lg:text-lg leading-8 lg:leading-9 font-jost">
                                <p>Thank you for submitting your documents. Unfortunately, after reviewing them, our admin team has found that they do not meet our requirements.</p>
                                <p><b className='text-black'>Reason for Rejection: </b>{isNotNullUndefinedBlank(user.rejectionReason) ? user.rejectionReason : "None"}</p>
                                <p><b className='text-black'>Next Steps:</b> Please upload the correct documents for verification. Please reach out to us at our official email address for further assistance and to discuss the next steps.</p>
                                <p><b className='text-black'>Important:</b> Your account will remain in the "Waiting for Approval" state until we receive and verify your updated documents. Once your documents are reviewed, you will receive a notification regarding the status of your approval.</p>
                                <p>For a seamless experience, we highly recommend using our mobile application. You can follow the steps below to install the app:</p>
                            </div>
                            <div className='mt-5 font-jost'>
                                <p className='font-bold text-black text-lg'>Download Our Mobile App:</p>
                                <div className="mt-4 flex flex-col gap-4 md:flex-row md:flex-wrap">
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">1</div>
                                        <p>Click on a store button below</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">2</div>
                                        <p>Try searching <span className='font-semibold'>"Tripattendynt"</span></p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">3</div>
                                        <p className='flex items-center gap-2.5'>Locate the  <div className='w-[150px]'>
                                            <img src={Images.smalllogo} className='cursor-pointer w-full' alt="triplogo" />
                                        </div> & click</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                        <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">4</div>
                                        <p><span className='font-semibold'>Click Install to complete process</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-center gap-5 mt-12'>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.googleplay} alt="Google play" />
                                </div>
                                <div className='w-1/2 sm:w-1/6'>
                                    <img src={Images.app} alt="Google play" />
                                </div>
                            </div>
                            <div className='text-center mt-5 font-jost text-base lg:text-[22px]'>
                                <p>Thank you for your cooperation. We look forward to working with you! </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <div>
                <p className='font-kalam text-center text-[26px] lg:text-[40px] mt-5 lg:mt-20'>Thank you for registering!</p>
                <div className="flex justify-center mt-2">
                    <div className="w-full sm:w-11/12 xl:w-4/5 xxl:w-2/3 p-8 lg:p-12 bg-white rounded-lg drop-shadow-lg">
                        <p className="font-bold text-lg lg:text-[22px] mb-2">Welcome to Tripattendynt!,</p>
                        <div className="text-gray-700 text-base lg:text-lg leading-8 lg:leading-9 font-jost">
                            <p>Thank you for submitting your documents. Unfortunately, after reviewing them, our admin team has found that they do not meet our requirements.</p>
                            <p><b className='text-black'>Reason for Rejection: </b>[Provide the specific reason for rejection here.]</p>
                            <p><b className='text-black'>Next Steps:</b> Please upload the correct documents for verification. Please reach out to us at our official email address for further assistance and to discuss the next steps.</p>
                            <p><b className='text-black'>Important:</b> Your account will remain in the "Waiting for Approval" state until we receive and verify your updated documents. Once your documents are reviewed, you will receive a notification regarding the status of your approval.</p>
                               <p>For a seamless experience, we highly recommend using our mobile application. You can follow the steps below to install the app:</p>
                        </div>
                        <div className='mt-5 font-jost'>
                            <p className='font-bold text-black text-lg'>Download Our Mobile App:</p>
                            <div className="mt-4 flex flex-col gap-4 md:flex-row md:flex-wrap">
                                <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                    <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">1</div>
                                    <p>Click on a store button below</p>
                                </div>
                                <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                    <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">2</div>
                                    <p>Try searching <span className='font-semibold'>"Tripattendynt"</span></p>
                                </div>
                                <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                    <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">3</div>
                                    <p className='flex items-center gap-2.5'>Locate the <img src={Images.smalllogo} className='cursor-pointer' alt="triplogo" /> & click</p>
                                </div>
                                <div className="flex items-center gap-2 rounded-lg bg-white w-full md:w-auto">
                                    <div className="flex justify-center items-center w-8 h-8 bg-gray-500 text-white rounded-full font-bold">4</div>
                                    <p><span className='font-semibold'>Click Install to complete process</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center justify-center gap-5 mt-12'>
                            <div className='w-1/2 sm:w-1/6'>
                                <img src={Images.googleplay} alt="Google play" />
                            </div>
                            <div className='w-1/2 sm:w-1/6'>
                                <img src={Images.app} alt="Google play" />
                            </div>
                        </div>
                        <div className='text-center mt-5 font-jost text-base lg:text-[22px]'>
                            <p>Thank you for your cooperation. We look forward to working with you! </p>
                        </div>
                    </div>
                </div>
           </div> */}

        </div>
    );
};

export default DownloadApplication;